import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vendor-dillegent-reponse',
  templateUrl: './reposnse-dillegent.component.html',
  styleUrls: ['./reposnse-dillegent.component.scss']
})
export class ReposnseDillegentComponent implements OnInit, AfterViewInit {

  temp : any; 
  @Input() childMessage: string;

  constructor() { 
  }
  ngAfterViewInit(): void {
    
  }

  ngOnInit(): void {
  }

}