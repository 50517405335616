import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VendorService } from '../_services/vendor.service';
@Component({
  selector: 'app-home-review',
  templateUrl: './home.review.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeReviewComponent implements OnInit{
  data:string;
  docs: any;
  reviews: any;
  isReviews: boolean = false;

  constructor(
    private vendorService: VendorService,
    private router: Router) {}

  ngOnInit(): void {
    this.loadData();
    this.loadReview();
      this.loadDoc();
  }

  onView(id:any){
    this.router.navigate([`/vendorreview/view/${id}`]);
  }

  onDownLoad(docid) {
    if (docid) {
        this.vendorService.downloadDoc(docid).subscribe(
            respon => {
                var fileURL = URL.createObjectURL(respon);
                window.open(fileURL);
            },
            error => {
                console.error(error)
            });
    }
}

  loadData() {
    this.vendorService.getDashboardCount().subscribe(
      respon => {
        if (respon) {
          this.data = respon;
        }
       
      },
      error => {
        console.error(error)
      });
  }

  loadDoc(){
    this.vendorService.getDashboardRecentDoc().subscribe(
      respon => {
        if (respon) {
          this.docs = respon;
        }
       
      },
      error => {
        console.error(error)
      });
  }

  loadReview(){
    this.vendorService.getDashboardVendor(0).subscribe(
      respon => {
        if (respon) {
          this.reviews = respon;
          if(this.reviews && this.reviews.length > 0){
            this.isReviews = true;
          }
        }
       
      },
      error => {
        console.error(error)
      });
  }
}
