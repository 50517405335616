import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Utilities } from 'src/app/_helpers/utilities';
import { PdfgeneratorService } from 'src/app/_services/pdf.service';
import { VendorService } from 'src/app/_services/vendor.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-vendor-viewapproval',
  templateUrl: './view-approval.component.html',
  styleUrls: ['./view-approval.component.scss']
})
export class ViewApprovalComponent implements OnInit {
  proccessNotApprove: Boolean = false;
  proccessApprove: Boolean = false;
  step: number = 0;
  isloadInfo: boolean = false;
  isloadBusiness: boolean = false;
  isloadDoc: boolean = false;
  isloadDue: boolean = false;
  vendorid: string;
  vInformation;
  vBusiness;
  vDoc;
  vDue;
  vRes1a;
  vRes2a;
  vRes2b;
  vRes3a;
  docSource: any;
  displayedColumns = ['DocName', 'No', 'StartDate', 'EndDate', 'Note', 'Action'];
  vSubmit: FormGroup;
  submitted = false;
  historySource: any;
  historydisplayedColumns = ['Name', 'Action', 'Activity', 'Date', 'Remark'];

  constructor(
    private route: ActivatedRoute,
    private vendorService: VendorService,
    private pdfService: PdfgeneratorService,
    private router: Router,
    private _formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    this.proccessApprove = false;
    this.proccessNotApprove = false;
    this.vendorid = this.route.snapshot.paramMap.get('id');
    this.loadVendor();
  }

  get f_sub() { return this.vSubmit.controls; }

  createFormSubmit() {
    let rcompliance = "", rcoi = "", rgoi = "";
    if (this.vInformation) {
      rcompliance = this.vInformation.resultDueCompliance;
      rcoi = this.vInformation.resultDueCOI;
      rgoi = this.vInformation.resultDueGOI;
    }
    setTimeout(() => {
      this.vSubmit = this._formBuilder.group({
        resultcompliance: [rcompliance, Validators.required],
        resultcoi: [rcoi, Validators.required],
        resultgoi: [rgoi, Validators.required],
        remark: [""]
      });
    });
  }

  loadVendor() {
    if (this.vendorid) {
      this.loadInformation();
      this.loadBusinnes();
      this.loadDocument();
      this.loadDue();
      this.loadHistory();
    }
  }

  loadHistory() {
    this.vendorService.getAllVendorHistoriesByReqVendorid(this.vendorid).subscribe(
      respon => {
        this.historySource = respon;
      },
      error => {
        console.error(error)
      });
  }

  loadInformation() {
    this.vendorService.getVendorInfoById(this.vendorid).subscribe(
      respon => {
        this.vInformation = respon;
        this.isloadInfo = true;
        this.createFormSubmit();
      },
      error => {
        console.error(error)
      });
  }

  loadBusinnes() {
    this.vendorService.getBusineessVendor(this.vendorid).subscribe(
      respon => {
        this.vBusiness = respon;
        this.isloadBusiness = true;
      },
      error => {
        console.error(error)
      });
  }

  loadDocument() {
    this.vendorService.getAllVendorDocByReqVendorid(this.vendorid).subscribe(
      respon => {
        this.docSource = respon;
        this.isloadDoc = true;
      },
      error => {
        console.error(error)
      });
  }

  loadDue() {
    this.vendorService.getDueVendor(this.vendorid)
      .subscribe(
        respon => {
          this.vDue = respon;
          this.isloadDue = true;
          this.vRes1a = respon.find(o => o.no === '1a').responseText;
          this.vRes2a = respon.find(o => o.no === '2a').details;
          this.vRes2b = respon.find(o => o.no === '2b').details;
          this.vRes3a = respon.find(o => o.no === '3a').responseText;
        },
        error => {
          console.error(error)
        }
      );
  }

  onDownLoad(row) {
    if (row) {
      this.vendorService.downloadDoc(row.objectId).subscribe(
        respon => {
          var fileURL = URL.createObjectURL(respon);
          window.open(fileURL);
        },
        error => {
          console.error(error)
        });
    }
  }

  onFileServiceDownload(e: any): void {
    this.vendorService.downloadBusineesDoc("service", this.vBusiness.objectId).subscribe(
      respon => {
        var fileURL = URL.createObjectURL(respon);
        window.open(fileURL);
      },
      error => {
        console.error(error)
      });
  }

  onFileItemDownload(e: any): void {
    this.vendorService.downloadBusineesDoc("item", this.vBusiness.objectId).subscribe(
      respon => {
        var fileURL = URL.createObjectURL(respon);
        window.open(fileURL);
      },
      error => {
        console.error(error)
      });
  }

  onNext() {
    setTimeout(() => {
      this.step = 1;
    })
  }

  onBackDue() {
    setTimeout(() => {
      this.step = 0;
    })
  }

  onNotApprove() {
    this.proccessNotApprove = true;
    this.submitted = true;
    if (this.vSubmit.invalid) {
      return;
    }
    const req = {
      vendorid: this.vendorid,
      resultcompliance: this.vSubmit.controls.resultcompliance.value,
      resultcoi: this.vSubmit.controls.resultcoi.value,
      resultgoi: this.vSubmit.controls.resultgoi.value,
      remark: this.vSubmit.controls.remark.value
    }
    this.vendorService.approveRejectedVendor(req)
      .subscribe(
        (res) => {
          this.proccessNotApprove = false;
          swal.fire({
            title: 'Not Approve Vendor Success!',
            text: res,
            icon: 'success',
            toast: false,
            allowOutsideClick: false,
            backdrop: true,
            confirmButtonText: 'Go To List Approval'
          }).then(_ => {
            this.router.navigate(['/vendorapproval']);
          })
        },
        (error) => {
          if (Utilities.checkNoNetwork(error)) {
            swal.fire({
              title: 'Not Approve Vendor Failed!',
              text: Utilities.noNetworkMessageDetail,
              icon: 'error',
              toast: false,
              allowOutsideClick: true
            })
          } else {
            swal.fire({
              title: 'Not Approve Vendor Failed!',
              text: Utilities.getResponseBody(error),
              icon: 'error',
              toast: false,
              allowOutsideClick: true
            })
          }
          this.proccessNotApprove = false;
        });
  }

  onDownLoadKTP(base64) {
    if (base64) {
      const parts = base64.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const decodedData = window.atob(parts[1]);
      const byteArray = new Uint8Array(decodedData.length);
      for (let i = 0; i < decodedData.length; ++i) {
        byteArray[i] = decodedData.charCodeAt(i);
      }
      const blob = new Blob([byteArray], { type: contentType });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    }
  }

  async onApprove() {
    this.proccessApprove = true;
    this.submitted = true;
    if (this.vSubmit.invalid) {
      return;
    }

    const req = {
      vendorid: this.vendorid,
      resultcompliance: this.vSubmit.controls.resultcompliance.value,
      resultcoi: this.vSubmit.controls.resultcoi.value,
      resultgoi: this.vSubmit.controls.resultgoi.value,
      remark: this.vSubmit.controls.remark.value,
    };

    this.vendorService.approveVendor(req)
      .subscribe(
        async (res) => {
          this.vInformation.vendorNo = res.vendorNo;
          this.vInformation.approverName = res.approverName;
          this.vInformation.startDate = res.startDate;
          this.vInformation.endDate = res.endDate;
          let pdf = await this.pdfService.generatePdfBase64(this.vInformation, this.docSource, this.vBusiness);
          const mail = {
            vendorid: this.vendorid,
            filepdf: pdf,
          };
          this.vendorService.approveVendorMail(mail)
            .subscribe(
              (res) => {
                this.proccessApprove = false;
                swal.fire({
                  title: 'Approve Vendor Success!',
                  text: res,
                  icon: 'success',
                  toast: false,
                  allowOutsideClick: false,
                  backdrop: true,
                  confirmButtonText: 'Go To List Approval'
                }).then(_ => {
                  this.router.navigate(['/vendorapproval']);
                })
              }
            )

        },
        (error) => {
          this.proccessApprove = false;
          if (Utilities.checkNoNetwork(error)) {
            swal.fire({
              title: 'Approve Vendor Failed!',
              text: Utilities.noNetworkMessageDetail,
              icon: 'error',
              toast: false,
              allowOutsideClick: true
            })
          } else {
            swal.fire({
              title: 'Approve Vendor Failed!',
              text: Utilities.getResponseBody(error),
              icon: 'error',
              toast: false,
              allowOutsideClick: true
            })
          }
        });
  }
}
