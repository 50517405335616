import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PasswordStrengthValidator } from 'src/app/_helpers/password-strength.validator';
import { Utilities } from 'src/app/_helpers/utilities';
import { UserService } from 'src/app/_services/user.service';
import swal from 'sweetalert2';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {
    isLoading = false;
    chgpwdForm: FormGroup;
    submitted = false;
    roles = [];
    countryCodes: any;

    constructor(
        private userService: UserService,
        private router: Router,
        private formBuilder: FormBuilder) {

    }

    get f() { return this.chgpwdForm.controls; }

    ngOnInit() {
        this.createForm();
    }

    onSubmit() {
        this.submitted = true;
        if (this.chgpwdForm.invalid) {
            return;
        }

        let chgpwd_model = {
            old_pwd :this.chgpwdForm.controls.old_pwd.value,
            new_pwd :this.chgpwdForm.controls.new_pwd.value
        }

        this.userService.changePassword(chgpwd_model).pipe()
            .subscribe(
                (res) => {
                    swal.fire({
                        title: 'Change Password Success!',
                        text: res,
                        icon: 'success',
                        toast: false,
                        allowOutsideClick: false,
                        backdrop: true,
                        confirmButtonText: 'Goto Home'
                    }).then(_ => {
                        this.router.navigate(['/']);
                    })
                },
                (error) => {
                    if (Utilities.checkNoNetwork(error)) {
                        swal.fire({
                            title: 'Change Password Failed!',
                            text: Utilities.noNetworkMessageDetail,
                            icon: 'error',
                            toast: false,
                            allowOutsideClick: true
                        })
                    } else {
                        swal.fire({
                            title: 'Change Password Failed!',
                            text: Utilities.getResponseBody(error),
                            icon: 'error',
                            toast: false,
                            allowOutsideClick: true
                        })
                    }
                });

        this.isLoading = true;

    }

    onCancel() {
        this.router.navigate(['/home']);
    }

    createForm() {
        setTimeout(() => {
            this.chgpwdForm = this.formBuilder.group({
                old_pwd: ['', [Validators.required, Validators.minLength(8)]],
                new_pwd: ['', [Validators.required, Validators.minLength(8), PasswordStrengthValidator]],
                conf_new_pwd: ['', [Validators.required, Validators.minLength(8)]],
            }, { validator: this.passwordConfirming });
        });
    }

    passwordConfirming(frm: FormGroup) {
        if (frm.controls['new_pwd'].value !== frm.controls['conf_new_pwd'].value) {
            frm.get('conf_new_pwd').setErrors({ 'mismatch': true });
            return { invalid: true };
        }
        if (frm.controls['old_pwd'].value === frm.controls['new_pwd'].value) {
            frm.get('new_pwd').setErrors({ 'oldsame': true });
            return { invalid: true };
        }
        return null;
    }

}