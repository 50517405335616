import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Utilities } from 'src/app/_helpers/utilities';
import { MasterService } from 'src/app/_services/master.service';
import { VendorService } from 'src/app/_services/vendor.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-vendor-view',
  templateUrl: './view-vendor.component.html',
  styleUrls: ['./view-vendor.component.scss']
})
export class ViewVendorComponent implements OnInit {
  step: number = 0;
  state: string = 'view';
  isloadInfo: boolean = false;
  isloadBusiness: boolean = false;
  isloadDoc: boolean = false;
  isloadDue: boolean = false;
  vendorid: string;
  vInformation;
  vBusiness;
  vDoc;
  vDue;
  vRes1a;
  vRes2a;
  vRes2b;
  vRes3a;
  docSource: any;
  displayedColumns = ['DocName', 'No', 'StartDate', 'EndDate', 'Note', 'Action'];
  vSubmit: FormGroup;
  submitted = false;
  vInfoBusinessEdit: FormGroup;
  vDueDiligent: FormGroup;
  serviceToUpload: File = null;
  itemToUpload: File = null;
  countryCodes: any;
  historySource: any;
  historydisplayedColumns = ['Name', 'Action', 'Activity', 'Date', 'Remark'];

  constructor(
    private route: ActivatedRoute,
    private vendorService: VendorService,
    private masterService: MasterService,
    private router: Router,
    private _formBuilder: FormBuilder) {

  }

  get f_infobusiness_edit() { return this.vInfoBusinessEdit.controls; }
  get f_due() { return this.vDueDiligent.controls; }

  ngOnInit(): void {
    this.vendorid = this.route.snapshot.paramMap.get('id');
    this.loadVendor();
    this.loadCountryCode();
  }

  get f_sub() { return this.vSubmit.controls; }

  createFormSubmit() {
    let rcompliance = "", rcoi = "", rgoi = "";
    if (this.vInformation) {
      rcompliance = this.vInformation.resultDueCompliance;
      rcoi = this.vInformation.resultDueCOI;
      rgoi = this.vInformation.resultDueGOI;
    }
    setTimeout(() => {
      this.vSubmit = this._formBuilder.group({
        resultcompliance: [rcompliance, Validators.required],
        resultcoi: [rcoi, Validators.required],
        resultgoi: [rgoi, Validators.required],
        remark: ["", Validators.required]
      });
    });
  }

  loadVendor() {
    if (this.vendorid) {
      this.step = 0;
      this.state = 'view';
      this.submitted = false;
      this.loadInformation();
      this.loadBusinnes();
      this.loadDocument();
      this.loadDue();
      this.loadHistory();
    }
  }

  loadHistory() {
    this.vendorService.getAllVendorHistoriesByReqVendorid(this.vendorid).subscribe(
      respon => {
        this.historySource = respon;
      },
      error => {
        console.error(error)
      });
  }

  loadInformation() {
    this.vendorService.getVendorInfoById(this.vendorid).subscribe(
      respon => {
        this.vInformation = respon;
        this.isloadInfo = true;
        this.createFormSubmit();
      },
      error => {
        console.error(error)
      });
  }

  createFormEditVendorInfoBusiness() {
    let vendorType = 'PT', vendorName = '', headOfficeAddress = '', hoNoTlpn = '', hoFax = '', hoEmail1 = '', sapNo = '',
      hoEmail2 = '', hoContactPerson1 = '', hoContactPerson2 = '', hoPhone1 = '', hoPhone2 = '', branchOfficeAddress = '',
      boNoTlpn = '', boFax = '', boEmail1 = '', boEmail2 = '', boContactPerson1 = '', boContactPerson2 = '', boPhone1 = '', boPhone2 = '',
      hoNoTlpn_cc = '+62', hoNoTlpn_dc = '+62', hoNoTlpn_ac = '', hoNoTlpn_n = '', hoFax_cc = '+62', hoFax_dc = '+62', hoFax_ac = '', hoFax_n = '',
      hoPhone1_cc = '+62', hoPhone1_dc = '+62', hoPhone1_ac = '', hoPhone1_n = '', hoPhone2_cc = '+62', hoPhone2_dc = '+62', hoPhone2_ac = '', hoPhone2_n = '',
      boNoTlpn_cc = '+62', boNoTlpn_dc = '+62', boNoTlpn_ac = '', boNoTlpn_n = '', boFax_cc = '+62', boFax_dc = '+62', boFax_ac = '', boFax_n = '',
      boPhone1_cc = '+62', boPhone1_dc = '+62', boPhone1_ac = '', boPhone1_n = '', boPhone2_cc = '+62', boPhone2_dc = '+62', boPhone2_ac = '', boPhone2_n = '',
      type = 'Kecil', field = '', director = '', commisioner = '', shareholder = '', scompany = '', isservice = false, isitem = false, sfname = '', ifname = '';

    if (this.vBusiness) {
      type = this.vBusiness.typeofBusiness;
      field = this.vBusiness.businessFields;
      director = this.vBusiness.presidentDirector;
      commisioner = this.vBusiness.presidentCommissioner;
      shareholder = this.vBusiness.shareholders;
      scompany = this.vBusiness.sisterCompany ?? '';
      isservice = this.vBusiness.isService;
      isitem = this.vBusiness.isItem;
      sfname = this.vBusiness.fileNameService;
      ifname = this.vBusiness.fileNameItem;
    }

    if (this.vInformation) {
      vendorType = this.vInformation.vendorType ?? '';
      vendorName = this.vInformation.vendorName ?? '';
      headOfficeAddress = this.vInformation.headOfficeAddress ?? '';
      hoNoTlpn = this.vInformation.hoNoTlpn ?? '';
      sapNo = this.vInformation.vendorSAPId ?? ''
      if (this.vInformation.hoNoTlpn) {
        let hoNoTlpnA = this.vInformation.hoNoTlpn.split('-');
        hoNoTlpn_cc = hoNoTlpnA[0];
        hoNoTlpn_dc = hoNoTlpnA[0];
        hoNoTlpn_ac = hoNoTlpnA[1];
        hoNoTlpn_n = hoNoTlpnA[2];
      }
      hoFax = this.vInformation.hoFax ?? '';
      if (this.vInformation.hoFax) {
        let hoFaxA = this.vInformation.hoFax.split('-');
        hoFax_cc = hoFaxA[0];
        hoFax_dc = hoFaxA[0];
        hoFax_ac = hoFaxA[1];
        hoFax_n = hoFaxA[2];
      }
      hoEmail1 = this.vInformation.hoEmail1 ?? '';
      hoEmail2 = this.vInformation.hoEmail2 ?? '';
      hoContactPerson1 = this.vInformation.hoContactPerson1 ?? '';
      hoContactPerson2 = this.vInformation.hoContactPerson2 ?? '';
      hoPhone1 = this.vInformation.hoPhone1 ?? '';
      if (this.vInformation.hoPhone1) {
        let hoPhone1A = this.vInformation.hoPhone1.split('-');
        hoPhone1_cc = hoPhone1A[0];
        hoPhone1_dc = hoPhone1A[0];
        hoPhone1_ac = hoPhone1A[1];
        hoPhone1_n = hoPhone1A[2];
      }
      hoPhone2 = this.vInformation.hoPhone2 ?? '';
      if (this.vInformation.hoPhone2) {
        let hoPhone2A = this.vInformation.hoPhone2.split('-');
        hoPhone2_cc = hoPhone2A[0];
        hoPhone2_dc = hoPhone2A[0];
        hoPhone2_ac = hoPhone2A[1];
        hoPhone2_n = hoPhone2A[2];
      }
      branchOfficeAddress = this.vInformation.branchOfficeAddress ?? '';
      boNoTlpn = this.vInformation.boNoTlpn ?? '';
      if (this.vInformation.boNoTlpn) {
        let boNoTlpnA = this.vInformation.boNoTlpn.split('-');
        boNoTlpn_cc = boNoTlpnA[0];
        boNoTlpn_dc = boNoTlpnA[0];
        boNoTlpn_ac = boNoTlpnA[1];
        boNoTlpn_n = boNoTlpnA[2];
      }
      boFax = this.vInformation.boFax ?? '';
      if (this.vInformation.boFax) {
        let boFaxA = this.vInformation.boFax.split('-');
        boFax_cc = boFaxA[0];
        boFax_dc = boFaxA[0];
        boFax_ac = boFaxA[1];
        boFax_n = boFaxA[2];
      }
      boEmail1 = this.vInformation.boEmail1 ?? '';
      boEmail2 = this.vInformation.boEmail2 ?? '';
      boContactPerson1 = this.vInformation.boContactPerson1 ?? '';
      boContactPerson2 = this.vInformation.boContactPerson2 ?? '';
      boPhone1 = this.vInformation.boPhone1 ?? '';

      if (this.vInformation.boPhone1) {
        let boPhone1A = this.vInformation.boPhone1.split('-');
        boPhone1_cc = boPhone1A[0];
        boPhone1_dc = boPhone1A[0];
        boPhone1_ac = boPhone1A[1];
        boPhone1_n = boPhone1A[2];
      }
      boPhone2 = this.vInformation.boPhone2 ?? '';
      if (this.vInformation.boPhone2) {
        let boPhone2A = this.vInformation.boPhone2.split('-');
        boPhone2_cc = boPhone2A[0];
        boPhone2_dc = boPhone2A[0];
        boPhone2_ac = boPhone2A[1];
        boPhone2_n = boPhone2A[2];
      }
    }

    setTimeout(() => {
      this.vInfoBusinessEdit = this._formBuilder.group({
        vendor_type: [vendorType],
        vendor_sap: [sapNo],
        vendor_name: [vendorName, Validators.required],
        vendor_address_p: [headOfficeAddress, Validators.required],
        vendor_tlpn_p: [hoNoTlpn, Validators.required],
        ho_tlpn_cc: [hoNoTlpn_cc],
        ho_tlpn_dc: [{ value: hoNoTlpn_dc, disabled: true }],
        ho_tlpn_ac: [hoNoTlpn_ac, Validators.required],
        ho_tlpn_n: [hoNoTlpn_n, Validators.required],
        vendor_fax_p: [hoFax, Validators.required],
        ho_fax_cc: [hoFax_cc],
        ho_fax_dc: [{ value: hoFax_dc, disabled: true }],
        ho_fax_ac: [hoFax_ac, Validators.required],
        ho_fax_n: [hoFax_n, Validators.required],
        vendor_emai1_p: [hoEmail1, [Validators.required, Validators.email]],
        vendor_emai2_p: [hoEmail2],
        vendor_cp1_p: [hoContactPerson1, Validators.required],
        vendor_cp2_p: [hoContactPerson2],
        vendor_phone1_p: [hoPhone1, [Validators.required]],
        ho_phone1_cc: [hoPhone1_cc],
        ho_phone1_dc: [{ value: hoPhone1_dc, disabled: true }],
        ho_phone1_ac: [hoPhone1_ac, Validators.required],
        ho_phone1_n: [hoPhone1_n, Validators.required],
        vendor_phone2_p: [hoPhone2],
        ho_phone2_cc: [hoPhone2_cc],
        ho_phone2_dc: [{ value: hoPhone2_dc, disabled: true }],
        ho_phone2_ac: [hoPhone2_ac],
        ho_phone2_n: [hoPhone2_n],
        vendor_address_c: [branchOfficeAddress],
        vendor_tlpn_c: [boNoTlpn],
        bo_tlpn_cc: [boNoTlpn_cc],
        bo_tlpn_dc: [{ value: boNoTlpn_dc, disabled: true }],
        bo_tlpn_ac: [boNoTlpn_ac],
        bo_tlpn_n: [boNoTlpn_n],
        vendor_fax_c: [boFax],
        bo_fax_cc: [boFax_cc],
        bo_fax_dc: [{ value: boFax_dc, disabled: true }],
        bo_fax_ac: [boFax_ac],
        bo_fax_n: [boFax_n],
        vendor_email1_c: [boEmail1],
        vendor_email2_c: [boEmail2],
        vendor_cp1_c: [boContactPerson1],
        vendor_cp2_c: [boContactPerson2],
        vendor_phone1_c: [boPhone1],
        bo_phone1_cc: [boPhone1_cc],
        bo_phone1_dc: [{ value: boPhone1_dc, disabled: true }],
        bo_phone1_ac: [boPhone1_ac],
        bo_phone1_n: [boPhone1_n],
        vendor_phone2_c: [boPhone2],
        bo_phone2_cc: [boPhone2_cc],
        bo_phone2_dc: [{ value: boPhone2_dc, disabled: true }],
        bo_phone2_ac: [boPhone2_ac],
        bo_phone2_n: [boPhone2_n],
        type_of_business: [type, Validators.required],
        business_fields: [field, Validators.required],
        president_director: [director, Validators.required],
        president_commisioner: [commisioner, Validators.required],
        shareholders: [shareholder, Validators.required],
        sister_company: [scompany],
        is_service: [isservice],
        is_item: [isitem],
        sfilename: [{ value: sfname, disabled: true }],
        ifilename: [{ value: ifname, disabled: true }]
      });
    });

  }

  createFormVendorDueDillegent() {
    if (this.vendorid) {
      let s_a = "", t_a = "";
      if (this.vDue.length > 3) {
        s_a = this.vDue.find(o => o.no === '1a').responseText;
        t_a = this.vDue.find(o => o.no === '3a').responseText;
      }
      this.vDueDiligent = this._formBuilder.group({
        satu_a: [s_a, Validators.required],
        dua_a: this._formBuilder.array([]),
        dua_b: this._formBuilder.array([]),
        tiga_a: [t_a, Validators.required],
      });
      this.initDuaA();
      this.initDuaB();
    }
  }

  addDuaA() {
    const c_dua_a = <FormArray>this.vDueDiligent.controls['dua_a'];
    let initA = this._formBuilder.group({
      name: [''],
      percentage: [0],
      place: [''],
    });
    c_dua_a.push(initA);
  }

  addDuaB() {
    const c_dua_b = <FormArray>this.vDueDiligent.controls['dua_b'];
    let initB = this._formBuilder.group({
      name: ['', Validators.required],
      company: ['', Validators.required],
      position: ['', Validators.required],
      base64: [null],
      filename: [''],
      ext: [''],
      contenttype: [''],
    });
    c_dua_b.push(initB);
  }

  removeDuaA(i) {
    const c_dua_a = <FormArray>this.vDueDiligent.controls['dua_a'];
    c_dua_a.removeAt(i);
  }

  removeDuaB(i) {
    const c_dua_b = <FormArray>this.vDueDiligent.controls['dua_b'];
    c_dua_b.removeAt(i);
  }

  initDuaA() {
    const c_dua_a = <FormArray>this.vDueDiligent.controls['dua_a'];
    if (this.vDue && this.vDue.length) {
      let d2as = this.vDue.find(o => o.no === '2a');
      if (d2as.details) {
        d2as.details.forEach(o2a => {
          let initA = this._formBuilder.group({
            name: [o2a.reponseColumn1],
            percentage: [o2a.reponseColumn2],
            place: [o2a.responseColumn3],
          });
          c_dua_a.push(initA);
        });
      }
      else {
        let initA = this._formBuilder.group({
          name: [''],
          percentage: [''],
          place: [''],
        });
        c_dua_a.push(initA);
      }
    }
    else {
      let initA = this._formBuilder.group({
        name: [''],
        percentage: [''],
        place: [''],
      });
      c_dua_a.push(initA);
    }
  }

  initDuaB() {
    const c_dua_b = <FormArray>this.vDueDiligent.controls['dua_b'];
    if (this.vDue && this.vDue.length) {
      let d2bs = this.vDue.find(o => o.no === '2b');
      if (d2bs.details && d2bs.details.length) {
        d2bs.details.forEach(o2b => {
          let initB = this._formBuilder.group({
            name: [o2b.reponseColumn1],
            company: [o2b.reponseColumn2],
            position: [o2b.responseColumn3],
            base64: [o2b.fileBase64],
            filename: [o2b.fileName],
            ext: [o2b.fileExtenstion],
            contenttype: [o2b.fileContentType],
          });
          c_dua_b.push(initB);
        });
      }
      else {
        let initB = this._formBuilder.group({
          name: ['', Validators.required],
          company: ['', Validators.required],
          position: ['', Validators.required],
          base64: [null],
          filename: [''],
          ext: [''],
          contenttype: [''],
        });
        c_dua_b.push(initB);
      }
    }
    else {
      let initB = this._formBuilder.group({
        name: ['', Validators.required],
        company: ['', Validators.required],
        position: ['', Validators.required],
        base64: [null],
        filename: [''],
        ext: [''],
        contenttype: [''],
      });
      c_dua_b.push(initB);
    }
  }

  loadBusinnes() {
    this.vendorService.getBusineessVendor(this.vendorid).subscribe(
      respon => {
        this.vBusiness = respon;
        this.isloadBusiness = true;
      },
      error => {
        console.error(error)
      });
  }

  loadDocument() {
    this.vendorService.getAllVendorDocByReqVendorid(this.vendorid).subscribe(
      respon => {
        this.docSource = respon;
        this.isloadDoc = true;
      },
      error => {
        console.error(error)
      });
  }

  loadDue() {
    this.vendorService.getDueVendor(this.vendorid)
      .subscribe(
        respon => {
          this.vDue = respon;
          this.isloadDue = true;
          this.vRes1a = respon.find(o => o.no === '1a')?.responseText;
          this.vRes2a = respon.find(o => o.no === '2a')?.details;
          this.vRes2b = respon.find(o => o.no === '2b')?.details;
          this.vRes3a = respon.find(o => o.no === '3a')?.responseText;
        },
        error => {
          console.error(error)
        }
      );
  }

  onDownLoad(row) {
    if (row) {
      this.vendorService.downloadDoc(row.objectId).subscribe(
        respon => {
          var fileURL = URL.createObjectURL(respon);
          window.open(fileURL);
        },
        error => {
          console.error(error)
        });
    }
  }

  onDownLoadKTP(base64) {
    if (base64) {
      const parts = base64.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const decodedData = window.atob(parts[1]);
      const byteArray = new Uint8Array(decodedData.length);
      for (let i = 0; i < decodedData.length; ++i) {
        byteArray[i] = decodedData.charCodeAt(i);
      }
      const blob = new Blob([byteArray], { type: contentType });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    }
  }

  onEditInfo() {
    this.createFormEditVendorInfoBusiness();
    this.step = 0;
    this.state = 'edit';
  }

  onEditInfoBusinessCancel() {
    this.step = 0;
    this.state = 'view';
  }

  onEditDueCancel() {
    this.step = 1;
    this.state = 'view';
    this.submitted = false;
  }

  fileChanged(e, i) {
    const c_dua_b = <FormArray>this.vDueDiligent.controls['dua_b'];
    const reader = new FileReader();
    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        c_dua_b.at(i).get('base64').patchValue(reader.result);
        let fname = e.target.files[0].name.split('.');
        let ftype = e.target.files[0].type;
        c_dua_b.at(i).get('filename').patchValue(fname[0]);
        c_dua_b.at(i).get('ext').patchValue(fname[1]);
        c_dua_b.at(i).get('contenttype').patchValue(ftype);
      };
    }
  }

  onEditDueSubmit() {
    if (this.vendorid) {
      let dueDillegents1a = {
        no: '1a',
        type: "TEXT",
        response: this.vDueDiligent.controls.satu_a.value,
        details: []
      };

      let dueDillegents3a = {
        no: '3a',
        type: "TEXT",
        response: this.vDueDiligent.controls.tiga_a.value,
        details: []
      }

      let dueDillegents2a = {
        no: '2a',
        type: "DETAIL",
        response: '',
        details: []
      };
      this.vDueDiligent.controls.dua_a.value.forEach(element => {
        if (element.name) {
          let o2a = {
            colum1: element.name,
            colum2: element.percentage.toString(),
            colum3: element.place,
          }
          dueDillegents2a.details.push(o2a);
        }
      });

      let dueDillegents2b = {
        no: '2b',
        type: "DETAIL",
        response: '',
        details: []
      };
      this.vDueDiligent.controls.dua_b.value.forEach(element => {
        if (element.name) {
          let o2b = {
            colum1: element.name,
            colum2: element.company,
            colum3: element.position,
            base64: element.base64,
            filename: element.filename,
            contenttype: element.contenttype,
            ext: element.ext
          }
          dueDillegents2b.details.push(o2b);
        }
      });


      const req = {
        vendorid: this.vendorid,
        dueDillegents: [dueDillegents1a, dueDillegents2a, dueDillegents2b, dueDillegents3a]
      }

      this.vendorService.submitVendorDue(req)
        .subscribe(
          (res) => {
            swal.fire({
              title: 'Edit Due Dilligent Review Success!',
              text: res,
              icon: 'success',
              toast: false,
              allowOutsideClick: false,
              backdrop: true,
              confirmButtonText: 'Ok'
            }).then(_ => {
              this.step = 1;
              this.state = 'view';
              this.submitted = false;
              this.loadDue();
            })
          },
          (error) => {
            if (Utilities.checkNoNetwork(error)) {
              swal.fire({
                title: 'Edit Due Dilligent Review Failed!',
                text: Utilities.noNetworkMessageDetail,
                icon: 'error',
                toast: false,
                allowOutsideClick: true
              })
            } else {
              swal.fire({
                title: 'Edit Due Dilligent Review Failed!',
                text: Utilities.getResponseBody(error),
                icon: 'error',
                toast: false,
                allowOutsideClick: true
              })
            }
          });
    }
  }

  onEditInfoBusinessSubmit() {
    this.submitted = true;
    if (this.vInfoBusinessEdit.invalid) {
      return;
    }

    this.vendorService.reviewEditInfo(this.vInformation.objectId,
                                      this.vInfoBusinessEdit.controls.vendor_type.value,
                                      this.vInfoBusinessEdit.controls.vendor_sap.value,
                                      this.vInfoBusinessEdit.controls.vendor_name.value,
                                      this.vInfoBusinessEdit.controls.vendor_address_p.value,
                                      this.vInfoBusinessEdit.controls.vendor_tlpn_p.value,
                                      this.vInfoBusinessEdit.controls.vendor_fax_p.value,
                                      this.vInfoBusinessEdit.controls.vendor_emai1_p.value,
                                      this.vInfoBusinessEdit.controls.vendor_emai2_p.value,
                                      this.vInfoBusinessEdit.controls.vendor_cp1_p.value,
                                      this.vInfoBusinessEdit.controls.vendor_cp2_p.value,
                                      this.vInfoBusinessEdit.controls.vendor_phone1_p.value,
                                      this.vInfoBusinessEdit.controls.vendor_phone2_p.value,
                                      this.vInfoBusinessEdit.controls.vendor_address_c.value,
                                      this.vInfoBusinessEdit.controls.vendor_tlpn_c.value,
                                      this.vInfoBusinessEdit.controls.vendor_fax_c.value,
                                      this.vInfoBusinessEdit.controls.vendor_email1_c.value,
                                      this.vInfoBusinessEdit.controls.vendor_email2_c.value,
                                      this.vInfoBusinessEdit.controls.vendor_cp1_c.value,
                                      this.vInfoBusinessEdit.controls.vendor_cp2_c.value,
                                      this.vInfoBusinessEdit.controls.vendor_phone1_c.value,
                                      this.vInfoBusinessEdit.controls.vendor_phone2_c.value,
                                      this.vInfoBusinessEdit.controls.type_of_business.value,
                                      this.vInfoBusinessEdit.controls.business_fields.value,
                                      this.vInfoBusinessEdit.controls.president_director.value,
                                      this.vInfoBusinessEdit.controls.president_commisioner.value,
                                      this.vInfoBusinessEdit.controls.shareholders.value,
                                      this.vInfoBusinessEdit.controls.sister_company.value,
                                      this.vInfoBusinessEdit.controls.is_item.value,
                                      this.vInfoBusinessEdit.controls.is_service.value,
                                      this.itemToUpload,
                                      this.serviceToUpload)
      .subscribe(
        (res) => {
          swal.fire({
            title: 'Edit Vendor Review Success!',
            text: res,
            icon: 'success',
            toast: false,
            allowOutsideClick: false,
            backdrop: true,
            confirmButtonText: 'Ok'
          }).then(_ => {
            this.loadVendor();
          })
        },
        (error) => {
          if (Utilities.checkNoNetwork(error)) {
            swal.fire({
              title: 'Edit Vendor Review Failed!',
              text: Utilities.noNetworkMessageDetail,
              icon: 'error',
              toast: false,
              allowOutsideClick: true,
            })
          } else {
            swal.fire({
              title: 'Edit Vendor Review Failed!',
              text: Utilities.getResponseBody(error),
              icon: 'error',
              toast: false,
              allowOutsideClick: true
            })
          }
        });
  }

  onNext() {
    setTimeout(() => {
      this.step = 1;
      this.state = 'view';
      this.submitted = false;
    })
  }

  onBackDue() {
    setTimeout(() => {
      this.step = 0;
      this.state = 'view';
      this.submitted = false;
    })
  }

  onEditDue() {
    this.createFormVendorDueDillegent();
    this.step = 1;
    this.state = 'edit';
    this.submitted = false;
  }

  onSubmit() {
    this.submitted = true;
    if (this.vSubmit.invalid) {
      return;
    }
    const req = {
      vendorid: this.vendorid,
      resultcompliance: this.vSubmit.controls.resultcompliance.value,
      resultcoi: this.vSubmit.controls.resultcoi.value,
      resultgoi: this.vSubmit.controls.resultgoi.value,
      remark: this.vSubmit.controls.remark.value
    }
    this.vendorService.reviewVendor(req)
      .subscribe(
        (res) => {
          swal.fire({
            title: 'Review Vendor Success!',
            text: res,
            icon: 'success',
            toast: false,
            allowOutsideClick: false,
            backdrop: true,
            confirmButtonText: 'Go To List Vendor'
          }).then(_ => {
            this.router.navigate(['/vendorreview']);
          })
        },
        (error) => {
          if (Utilities.checkNoNetwork(error)) {
            swal.fire({
              title: 'Review Vendor Failed!',
              text: Utilities.noNetworkMessageDetail,
              icon: 'error',
              toast: false,
              allowOutsideClick: true
            })
          } else {
            swal.fire({
              title: 'Review Vendor Failed!',
              text: Utilities.getResponseBody(error),
              icon: 'error',
              toast: false,
              allowOutsideClick: true
            })
          }
        });
  }


  onRejected() {
    this.submitted = true;
    if (this.vSubmit.invalid) {
      return;
    }
    const req = {
      vendorid: this.vendorid,
      resultcompliance: this.vSubmit.controls.resultcompliance.value,
      resultcoi: this.vSubmit.controls.resultcoi.value,
      resultgoi: this.vSubmit.controls.resultgoi.value,
      remark: this.vSubmit.controls.remark.value
    }
    this.vendorService.reviewRejectedVendor(req)
      .subscribe(
        (res) => {
          swal.fire({
            title: 'Rejected Vendor Success!',
            text: res,
            icon: 'success',
            toast: false,
            allowOutsideClick: false,
            backdrop: true,
            confirmButtonText: 'Go To List Vendor'
          }).then(_ => {
            this.router.navigate(['/vendorreview']);
          })
        },
        (error) => {
          if (Utilities.checkNoNetwork(error)) {
            swal.fire({
              title: 'Rejected Vendor Failed!',
              text: Utilities.noNetworkMessageDetail,
              icon: 'error',
              toast: false,
              allowOutsideClick: true
            })
          } else {
            swal.fire({
              title: 'Rejected Vendor Failed!',
              text: Utilities.getResponseBody(error),
              icon: 'error',
              toast: false,
              allowOutsideClick: true
            })
          }
        });
  }

  onFileServiceSelect(e: any): void {
    try {
      const file = e.target.files[0];
      this.serviceToUpload = file;
      const fReader = new FileReader()
      fReader.readAsDataURL(file)
      fReader.onloadend = (_event: any) => {
        this.vInfoBusinessEdit.get('sfilename').setValue(file.name);
      }
    } catch (error) {
      this.vInfoBusinessEdit.get('sfilename').setValue(null);
      this.serviceToUpload = null;
    }
  }

  onFileServiceDownload(e: any): void {
    if (this.vInfoBusinessEdit.get('sfilename')) {
      if (this.serviceToUpload) {
        let fileURL = URL.createObjectURL(this.serviceToUpload);
        window.open(fileURL);
      }
      else {
        this.vendorService.downloadBusineesDoc("service", this.vBusiness.objectId).subscribe(
          respon => {
            var fileURL = URL.createObjectURL(respon);
            window.open(fileURL);
          },
          error => {
            console.error(error)
          });
      }
    }
  }


  onFileItemSelect(e: any): void {
    try {
      const file = e.target.files[0];
      this.itemToUpload = file;
      const fReader = new FileReader()
      fReader.readAsDataURL(file)
      fReader.onloadend = (_event: any) => {
        this.vBusiness.get('ifilename').setValue(file.name);
      }
    } catch (error) {
      this.vBusiness.get('ifilename').setValue(null);
      this.itemToUpload = null;
    }
  }

  onFileItemDownload(e: any): void {
    if (this.vBusiness.get('ifilename')) {
      if (this.itemToUpload) {
        let fileURL = URL.createObjectURL(this.itemToUpload);
        window.open(fileURL);
      }
      else {
        this.vendorService.downloadBusineesDoc("item", this.vBusiness.objectId).subscribe(
          respon => {
            var fileURL = URL.createObjectURL(respon);
            window.open(fileURL);
          },
          error => {
            console.error(error)
          });
      }
    }
  }

  loadCountryCode() {
    this.masterService.GetCountryCode()
      .subscribe(
        respon => {
          this.countryCodes = respon;
        },
        error => {
          console.error(error)
        }
      );
  }

  onChangeTlpnHO(e) {
    if (e.target.name === 'ho_tlpn_cc') {
      this.f_infobusiness_edit.ho_tlpn_dc.setValue(e.target.value);
    }

    if (this.f_infobusiness_edit.ho_tlpn_dc.value && this.f_infobusiness_edit.ho_tlpn_ac.value && this.f_infobusiness_edit.ho_tlpn_n.value) {
      this.f_infobusiness_edit.vendor_tlpn_p.setValue(`${this.f_infobusiness_edit.ho_tlpn_dc.value}-${this.f_infobusiness_edit.ho_tlpn_ac.value}-${this.f_infobusiness_edit.ho_tlpn_n.value}`);
    }
  }

  onChangeFaxHO(e) {
    if (e.target.name === 'ho_fax_cc') {
      this.f_infobusiness_edit.ho_fax_dc.setValue(e.target.value);
    }

    if (this.f_infobusiness_edit.ho_fax_dc.value && this.f_infobusiness_edit.ho_fax_ac.value && this.f_infobusiness_edit.ho_fax_n.value) {
      this.f_infobusiness_edit.vendor_fax_p.setValue(`${this.f_infobusiness_edit.ho_fax_dc.value}-${this.f_infobusiness_edit.ho_fax_ac.value}-${this.f_infobusiness_edit.ho_fax_n.value}`);
    }
  }

  onChangePhone1HO(e) {
    if (e.target.name === 'ho_phone1_cc') {
      this.f_infobusiness_edit.ho_phone1_dc.setValue(e.target.value);
    }

    if (this.f_infobusiness_edit.ho_phone1_dc.value && this.f_infobusiness_edit.ho_phone1_ac.value && this.f_infobusiness_edit.ho_phone1_n.value) {
      this.f_infobusiness_edit.vendor_phone1_p.setValue(`${this.f_infobusiness_edit.ho_phone1_dc.value}-${this.f_infobusiness_edit.ho_phone1_ac.value}-${this.f_infobusiness_edit.ho_phone1_n.value}`);
    }
  }

  onChangePhone2HO(e) {
    if (e.target.name === 'ho_phone2_cc') {
      this.f_infobusiness_edit.ho_phone2_dc.setValue(e.target.value);
    }

    if (this.f_infobusiness_edit.ho_phone2_dc.value && this.f_infobusiness_edit.ho_phone2_ac.value && this.f_infobusiness_edit.ho_phone2_n.value) {
      this.f_infobusiness_edit.vendor_phone2_p.setValue(`${this.f_infobusiness_edit.ho_phone2_dc.value}-${this.f_infobusiness_edit.ho_phone2_ac.value}-${this.f_infobusiness_edit.ho_phone2_n.value}`);
    }
  }

  onChangeTlpnBO(e) {
    if (e.target.name === 'bo_tlpn_cc') {
      this.f_infobusiness_edit.bo_tlpn_dc.setValue(e.target.value);
    }

    if (this.f_infobusiness_edit.bo_tlpn_dc.value && this.f_infobusiness_edit.bo_tlpn_ac.value && this.f_infobusiness_edit.bo_tlpn_n.value) {
      this.f_infobusiness_edit.vendor_tlpn_c.setValue(`${this.f_infobusiness_edit.bo_tlpn_dc.value}-${this.f_infobusiness_edit.bo_tlpn_ac.value}-${this.f_infobusiness_edit.bo_tlpn_n.value}`);
    }
  }

  onChangeFaxBO(e) {
    if (e.target.name === 'bo_fax_cc') {
      this.f_infobusiness_edit.bo_fax_dc.setValue(e.target.value);
    }

    if (this.f_infobusiness_edit.bo_fax_dc.value && this.f_infobusiness_edit.bo_fax_ac.value && this.f_infobusiness_edit.bo_fax_n.value) {
      this.f_infobusiness_edit.vendor_fax_c.setValue(`${this.f_infobusiness_edit.bo_fax_dc.value}-${this.f_infobusiness_edit.bo_fax_ac.value}-${this.f_infobusiness_edit.bo_fax_n.value}`);
    }
  }

  onChangePhone1BO(e) {
    if (e.target.name === 'bo_phone1_cc') {
      this.f_infobusiness_edit.bo_phone1_dc.setValue(e.target.value);
    }

    if (this.f_infobusiness_edit.bo_phone1_dc.value && this.f_infobusiness_edit.bo_phone1_ac.value && this.f_infobusiness_edit.bo_phone1_n.value) {
      this.f_infobusiness_edit.vendor_phone1_c.setValue(`${this.f_infobusiness_edit.bo_phone1_dc.value}-${this.f_infobusiness_edit.bo_phone1_ac.value}-${this.f_infobusiness_edit.bo_phone1_n.value}`);
    }
  }

  onChangePhone2BO(e) {
    if (e.target.name === 'bo_phone2_cc') {
      this.f_infobusiness_edit.bo_phone2_dc.setValue(e.target.value);
    }

    if (this.f_infobusiness_edit.bo_phone2_dc.value && this.f_infobusiness_edit.bo_phone2_ac.value && this.f_infobusiness_edit.bo_phone2_n.value) {
      this.f_infobusiness_edit.vendor_phone2_c.setValue(`${this.f_infobusiness_edit.bo_phone2_dc.value}-${this.f_infobusiness_edit.bo_phone2_ac.value}-${this.f_infobusiness_edit.bo_phone2_n.value}`);
    }
  }

  dueByFn(index: any, item: any) {
    return index;
  }
}
