import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../_services/user.service';
import { VendorService } from '../_services/vendor.service';
@Component({
  selector: 'app-home-admin',
  templateUrl: './home.admin.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeAdminComponent implements OnInit {
  data: any;
  datauser: any;

  constructor(
    private vendorService: VendorService,
    private userService: UserService,
    private router: Router
  ) {

  }
  ngOnInit(): void {
    this.loadData();
    this.loadDataUser();
  }

  loadData() {
    this.vendorService.getDashboardCount().subscribe(
      respon => {
        if (respon) {
          this.data = respon;
        }

      },
      error => {
        console.error(error)
      });
  }

  loadDataUser() {
    this.userService.getDashboardCount().subscribe(
      respon => {
        if (respon) {
          this.datauser = respon;
        }

      },
      error => {
        console.error(error)
      });
  }

}
