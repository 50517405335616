import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Utilities } from 'src/app/_helpers/utilities';
import { VendorService } from 'src/app/_services/vendor.service';
import swal from 'sweetalert2';

@Component({
    selector: 'view-vendor',
    templateUrl: './vendor-view.component.html',
    styleUrls: ['./vendor-view.component.scss']
})
export class VendorViewUserComponent implements OnInit {
    step: number = 0;
    isloadInfo: boolean = false;
    isloadBusiness: boolean = false;
    isloadDoc: boolean = false;
    isloadDue: boolean = false;
    vRes1a;
    vRes2a;
    vRes2b;
    vRes3a;
    docSource: any;
    displayedColumns = ['DocName', 'No', 'StartDate', 'EndDate', 'Note', 'Action'];
    vSubmit: FormGroup;
    submitted = false;
    

    @Input("vendorid") vendorid: string;
    @Input("vInformation") vInformation: any;
    @Input("vBusiness") vBusiness: any;
    @Input("vDoc") vDoc: any;
    @Input("vDue") vDue: any;

    constructor(
        private route: ActivatedRoute,
        private vendorService: VendorService,
        private router: Router) {
    }

    ngOnInit(): void {
        this.loadDue();
        this.checkLoad();
    }

    checkLoad() {
        if (this.vInformation)
            this.isloadInfo = true;
        if (this.vBusiness)
            this.isloadBusiness = true;
        if (this.vDoc){
            this.isloadDoc = true;
            this.docSource = this.vDoc;
        }
          
        if (this.vDue)
            this.isloadDue = true;
    }

    loadDue() {
        if (this.vDue) {
            this.vRes1a = this.vDue.find(o => o.no === '1a')?.responseText;
            this.vRes2a = this.vDue.find(o => o.no === '2a')?.details;
            this.vRes2b = this.vDue.find(o => o.no === '2b')?.details;
            this.vRes3a = this.vDue.find(o => o.no === '3a')?.responseText;
        }
    }

    onDownLoad(row) {
        if (row) {
            this.vendorService.downloadDoc(row.objectId).subscribe(
                respon => {
                    var fileURL = URL.createObjectURL(respon);
                    window.open(fileURL);
                },
                error => {
                    console.error(error)
                });
        }
    }

    onDownLoadKTP(base64) {
        if (base64) {
          const parts = base64.split(';base64,');
          const contentType = parts[0].split(':')[1];
          const decodedData = window.atob(parts[1]);
          const byteArray = new Uint8Array(decodedData.length);
          for (let i = 0; i < decodedData.length; ++i) {
            byteArray[i] = decodedData.charCodeAt(i);
          }
          const blob = new Blob([byteArray], {type: contentType});
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        }
      }

    onFileServiceDownload(e: any): void {
        this.vendorService.downloadBusineesDoc("service", this.vBusiness.objectId).subscribe(
            respon => {
                var fileURL = URL.createObjectURL(respon);
                window.open(fileURL);
            },
            error => {
                console.error(error)
            });
    }

    onFileItemDownload(e: any): void {
        this.vendorService.downloadBusineesDoc("item", this.vBusiness.objectId).subscribe(
            respon => {
                var fileURL = URL.createObjectURL(respon);
                window.open(fileURL);
            },
            error => {
                console.error(error)
            });
    }

    onNext() {
        this.loadDue();
        setTimeout(() => {
            this.step = 1;
        })
    }

    onBack() {
        setTimeout(() => {
            this.step = 0;
        })
    }
}
