import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChildren, AfterViewInit, QueryList, ElementRef, ɵConsole  } from '@angular/core';
import { ToastaService, ToastaConfig, ToastOptions, ToastData } from 'ngx-toasta';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppTitleService } from './_helpers/app-title.helper';
import { LoginComponent } from './login/login.component';
import { AlertCommand, AlertDialog, AlertService, DialogType, MessageSeverity } from './_helpers/alert.helper';
import { Router } from '@angular/router';
import { TokenStorage } from './_helpers/token-storage';
import { AuthService } from './_services/auth.service';

const alertify: any = require('./assets/scripts/alertify.js');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy{
  appTitle = 'SKKV';
  appBrandName = 'SKKV VENDOR QUALIFICATION ONLINE';
  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  private roles: string[] = [];
  userid?: string;
  username?: string;
  fullname?: string;
  email?: string;
  phone?: string;

  isAppLoaded: boolean;
  isUserLoggedIn: boolean;
  showUserVendor: boolean;
  showVendorManagement: boolean;
  showTeamManager: boolean;
  showAdmin: boolean;
  shouldShowLoginModal: boolean;
  removePrebootScreen: boolean;

  @ViewChildren('loginModal,loginControl')
  modalLoginControls: QueryList<any>;
  stickyToasties: number[] = [];
  loginControl: LoginComponent;
  loginModal: ModalDirective;

  constructor(
    private tokenStorage: TokenStorage,
    public router: Router,
    private toastaService: ToastaService,
    private toastaConfig: ToastaConfig,
    private alertService: AlertService,
    private authService: AuthService,
    private appTitleService: AppTitleService,){
      this.toastaConfig.theme = 'bootstrap';
      this.toastaConfig.position = 'top-right';
      this.toastaConfig.limit = 100;
      this.toastaConfig.showClose = true;
      this.toastaConfig.showDuration = false;
      this.appTitleService.appName = this.appTitle;
      this.isUserLoggedIn = this.tokenStorage.getToken() != null;
  }

  get isAuthorized() {
    return this.tokenStorage.getToken() != null;
  }

  get isShowUserVendor() {
    if(this.tokenStorage.getToken() != null){
      const roles = [];
      const tokeninfo = this.tokenStorage.getTokenInfo();
      let rols = tokeninfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      if (rols instanceof Array) {
        rols.forEach(r => {
          roles.push(r);
        });
      } else {
        roles.push(rols);
      }
      return roles.some(r => r === "ROLE_VENDOR");
    } 
    return false;
  }

  get isShowVendorManagement() {
    if(this.tokenStorage.getToken() != null){
      const roles = [];
      const tokeninfo = this.tokenStorage.getTokenInfo();
      let rols = tokeninfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      if (rols instanceof Array) {
        rols.forEach(r => {
          roles.push(r);
        });
      } else {
        roles.push(rols);
      }
      return roles.some(r => r === "ROLE_VENDOR_MANAGEMENT");
    } 
    return false;
  }

  get isShowTeamManager() {
    if(this.tokenStorage.getToken() != null){
      const roles = [];
      const tokeninfo = this.tokenStorage.getTokenInfo();
      let rols = tokeninfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      if (rols instanceof Array) {
        rols.forEach(r => {
          roles.push(r);
        });
      } else {
        roles.push(rols);
      }
      return roles.some(r => r === "ROLE_TEAM_MANAGER");
    } 
    return false;
  }

  get isShowAdmin() {
    if(this.tokenStorage.getToken() != null){
      const roles = [];
      const tokeninfo = this.tokenStorage.getTokenInfo();
      const rols = tokeninfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      if (rols instanceof Array) {
        rols.forEach(r => {
          roles.push(r);
        });
      } else {
        roles.push(rols);
      }
      return roles.some(r => r === "ROLE_ADMIN");
    } 
    return false;
  }
  

  ngOnInit(): void {
    this.isUserLoggedIn = this.tokenStorage.getToken() != null;
    if (this.isUserLoggedIn) {
      const tokeninfo = this.tokenStorage.getTokenInfo();
      this.roles = tokeninfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      this.showAdmin = this.roles.includes('ROLE_ADMIN');
      this.showTeamManager = this.roles.includes('ROLE_TEAM_MANAGER');
      this.showVendorManagement = this.roles.includes('ROLE_VENDOR_MANAGEMENT');
      this.showUserVendor = this.roles.includes('ROLE_VENDOR');

      this.userid = tokeninfo.userid;
      this.username = tokeninfo.username;
      this.email = tokeninfo.email;
      this.fullname = tokeninfo.fullname;
      this.phone = tokeninfo.phone;
    }
    else{
      this.roles = [];
      this.showAdmin = false;
      this.showTeamManager = false;
      this.showVendorManagement = false;
      this.showUserVendor = false;

      this.userid = null
      this.username = null;
      this.email = null;
      this.fullname = null;
      this.phone = null;
    }
    setTimeout(() => this.isAppLoaded = true, 500);
    setTimeout(() => this.removePrebootScreen = true, 1000);
    
    this.alertService.getDialogEvent().subscribe(alert => this.showDialog(alert));
    this.alertService.getMessageEvent().subscribe(message => this.showToast(message));
  }

  ngAfterViewInit(): void {
    this.modalLoginControls.changes.subscribe((controls: QueryList<any>) => {
      controls.forEach(control => {
        if (control) {
          if (control instanceof LoginComponent) {
            this.loginControl = control;
            this.loginControl.modalClosedCallback = () => this.loginModal.hide();
          } else {
            this.loginModal = control;
            this.loginModal.show();
          }
        }
      });
    });
    
  }
  ngOnDestroy(): void {
   
  }
  
  onLoginModalShown() {
    this.alertService.showStickyMessage('Session Expired', 'Your Session has expired. Please log in again', MessageSeverity.info);
  }

  onLoginModalHidden() {
    this.alertService.resetStickyMessage();
    this.loginControl.reset();
    this.shouldShowLoginModal = false;

    // if (this.authService.isSessionExpired) {
    //   this.alertService.showStickyMessage('Session Expired', 'Your Session has expired. Please log in again to renew your session', MessageSeverity.warn);
    // }
  }

  showDialog(dialog: AlertDialog) {

    alertify.set({
      labels: {
        ok: dialog.okLabel || 'OK',
        cancel: dialog.cancelLabel || 'Cancel'
      }
    });

    switch (dialog.type) {
      case DialogType.alert:
        alertify.alert(dialog.message);

        break;
      case DialogType.confirm:
        alertify
          .confirm(dialog.message, (e) => {
            if (e) {
              dialog.okCallback();
            } else {
              if (dialog.cancelCallback) {
                dialog.cancelCallback();
              }
            }
          });

        break;
      case DialogType.prompt:
        alertify
          .prompt(dialog.message, (e, val) => {
            if (e) {
              dialog.okCallback(val);
            } else {
              if (dialog.cancelCallback) {
                dialog.cancelCallback();
              }
            }
          }, dialog.defaultValue);

        break;
    }
  }

  showToast(alert: AlertCommand) {

    if (alert.operation === 'clear') {
      for (const id of this.stickyToasties.slice(0)) {
        this.toastaService.clear(id);
      }

      return;
    }

    const toastOptions: ToastOptions = {
      title: alert.message.summary,
      msg: alert.message.detail,
    };


    if (alert.operation === 'add_sticky') {
      toastOptions.timeout = 0;

      toastOptions.onAdd = (toast: ToastData) => {
        this.stickyToasties.push(toast.id);
      };

      toastOptions.onRemove = (toast: ToastData) => {
        const index = this.stickyToasties.indexOf(toast.id, 0);

        if (index > -1) {
          this.stickyToasties.splice(index, 1);
        }

        if (alert.onRemove) {
          alert.onRemove();
        }

        toast.onAdd = null;
        toast.onRemove = null;
      };
    } else {
      toastOptions.timeout = 4000;
    }


    switch (alert.message.severity) {
      case MessageSeverity.default: this.toastaService.default(toastOptions); break;
      case MessageSeverity.info: this.toastaService.info(toastOptions); break;
      case MessageSeverity.success: this.toastaService.success(toastOptions); break;
      case MessageSeverity.error: this.toastaService.error(toastOptions); break;
      case MessageSeverity.warn: this.toastaService.warning(toastOptions); break;
      case MessageSeverity.wait: this.toastaService.wait(toastOptions); break;
    }
  }

  logout() {
    this.authService.logout();
  }

  changePwd(){
    this.router.navigate(['/chgpwd']);
  }

  profile(){
    this.router.navigate(['/profile']);
  }

  getYear() {
    return new Date().getUTCFullYear();
  }
}
