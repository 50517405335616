import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Utilities } from 'src/app/_helpers/utilities';
import { RegisterModel } from 'src/app/_models/register-model';
import { UpdateUserModel } from 'src/app/_models/update-user-model';
import { MasterService } from 'src/app/_services/master.service';
import { UserService } from 'src/app/_services/user.service';
import swal from 'sweetalert2';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.scss']
})

export class UserEditComponent implements OnInit {
    userid: string;
    user: any;
    isLoading = false;
    editForm: FormGroup;
    submitted = false;
    roles = [];
    countryCodes: any;

    constructor(
        private masterService: MasterService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private formBuilder: FormBuilder) {

    }

    ngOnInit() {
        this.userid = this.route.snapshot.paramMap.get('id');
        this.loadCountryCode();
        this.loadRoles();
        this.loadUser();
    }

    // convenience getter for easy access to form fields
    get f() { return this.editForm.controls; }

    get rolesFormArray() {
        return this.editForm.controls.roleusers as FormArray;
    }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.editForm.invalid) {
            return;
        }

        this.isLoading = true;

        const updateModel: UpdateUserModel = {
            userid: this.userid,
            address: '',
            email: this.editForm.controls.email.value,
            firstname: this.editForm.controls.firstName.value,
            gender: this.editForm.controls.gender.value,
            lastname: this.editForm.controls.lastName.value,
            phone: this.editForm.controls.phone.value,
            roles: this.editForm.controls.roleusers.value,
        }

        this.userService.updateUser(updateModel).pipe()
            .subscribe(
                (res) => {
                    swal.fire({
                        title: 'Update user Success!',
                        text: res,
                        icon: 'success',
                        toast: false,
                        allowOutsideClick: false,
                        backdrop: true,
                        confirmButtonText: 'Goto User List'
                    }).then(_ => {
                        this.router.navigate(['/user']);
                    })
                },
                (error) => {
                    if (Utilities.checkNoNetwork(error)) {
                        swal.fire({
                            title: 'Update user Failed!',
                            text: Utilities.noNetworkMessageDetail,
                            icon: 'error',
                            toast: false,
                            allowOutsideClick: true
                        })
                    } else {
                        swal.fire({
                            title: 'Update user Failed!',
                            text: Utilities.getResponseBody(error),
                            icon: 'error',
                            toast: false,
                            allowOutsideClick: true
                        })
                    }
                });
    }

    onCheckboxChange(e) {
        const roless: FormArray = this.editForm.get('roleusers') as FormArray;

        if (e.target.checked) {
            roless.push(new FormControl(e.target.value));
        } else {
            const index = roless.controls.findIndex(x => x.value === e.target.value);
            roless.removeAt(index);
        }
    }

    loadRoles() {
        this.userService.getAllRoles().subscribe(
            respon => {

                if (respon) {
                    respon.forEach(element => {
                        let role = {
                            id: element.objectId,
                            name: element.roleName.replace('_', ' '),
                            checked: false
                        }
                        this.roles.push(role);
                    });
                }
            },
            error => {
                console.error(error)
            });
    }

    loadUser() {
        if (this.userid) {
            this.userService.getUserId(this.userid).subscribe(
                respon => {
                    if (respon) {
                        this.user = respon;
                        this.createForm();
                    }
                },
                error => {
                    console.error(error)
                });
        }
    }

    createForm() {
        let firstname = '', lastname = '', email = '', gender = '', phone = '', phone_cc = '+62', phone_dc = '+62', phone_ac = '', phone_n = '';
        let userRoles = [];
        if (this.user) {
            firstname = this.user.firstname ?? '';
            lastname = this.user.lastname ?? '';
            email = this.user.email ?? '';
            gender = this.user.gender ?? 'Laki-Laki';
            phone = this.user.phone ?? '';
            this.user.userRoles.forEach(ur => {
                this.roles.find(item => item.id == ur.role.objectId).checked = true;
                userRoles.push(ur.role.objectId);
            });

            if (this.user.phone) {
                let phonea = this.user.phone.split('-');
                phone_cc = phonea[0];
                phone_dc = phonea[0];
                phone_ac = phonea[1];
                phone_n = phonea[2];
            }
        }
        setTimeout(() => {
            this.editForm = this.formBuilder.group({
                firstName: [firstname, Validators.required],
                lastName: [lastname],
                email: [email, [Validators.required, Validators.email]],
                gender: [gender],
                phone: [phone, Validators.required],
                phone_cc: [phone_cc],
                phone_dc: [{ value: phone_dc, disabled: true }],
                phone_ac: [phone_ac],
                phone_n: [phone_n],
                roleusers: this.formBuilder.array(userRoles, [Validators.required])
            });
        });
    }

    onChangePhone(e) {
        if (e.target.name === 'phone_cc') {
            this.f.phone_dc.setValue(e.target.value);
        }

        if (this.f.phone_dc.value && this.f.phone_ac.value && this.f.phone_n.value) {
            this.f.phone.setValue(`${this.f.phone_dc.value}-${this.f.phone_ac.value}-${this.f.phone_n.value}`);
        }
    }

    onReset() {
        this.submitted = false;
        this.editForm.reset();
    }

    onCancel() {
        this.router.navigate(['/user']);
    }

    loadCountryCode() {
        this.masterService.GetCountryCode()
            .subscribe(
                respon => {
                    this.countryCodes = respon;
                },
                error => {
                    console.error(error)
                }
            );
    }
}
