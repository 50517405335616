import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utilities } from 'src/app/_helpers/utilities';
import { VendorService } from 'src/app/_services/vendor.service';
import swal from 'sweetalert2';

@Component({
    selector: 'app-add-vendordoc',
    templateUrl: './add-vendordoc.component.html',
    styleUrls: ['./add-vendordoc.component.scss']
})
export class AddVendorDocComponent implements OnInit {
    public breakpoint: number; // Breakpoint observer code
    public docForm: FormGroup;
    wasFormChanged = false;
    fileToUpload: File = null;
    modalType = 0;
    doc: any;
    constructor(
        private vendorService: VendorService,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<AddVendorDocComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.doc = data;
        if(data.doc_name == 'Akte Pendirian Perusahaan' || data.doc_name == 'Akte Perubahan Terakhir'){
            this.modalType = 1;
        }
        else if(data.doc_name == 'SK Pengesahan Akte Pendirian' || data.doc_name == 'SK Pengesahan Akte Perubahan Terakhir'){
            this.modalType = 2;
        }
        else{
            this.modalType = 0;
        }
    }

    public ngOnInit(): void {
        this.setFormDocument();
    }

    onFileSelect(e: any): void {
        try {
            const file = e.target.files[0];
            this.fileToUpload = file;
            const fReader = new FileReader()
            fReader.readAsDataURL(file)
            fReader.onloadend = (_event: any) => {
                this.docForm.get('filename').setValue(file.name);
            }
        } catch (error) {
            this.docForm.get('filename').setValue(null);
            this.fileToUpload = null;
        }
    }

    setFormDocument() {
        this.docForm = this.fb.group({
            doc_name: [{ value: this.doc.doc_name, disabled: true }],
            doc_no: [this.doc.doc_no],
            start_date: [this.doc.start_date],
            end_date: [this.doc.end_date],
            notaris: [this.doc.notaris],
            description: [this.doc.description],
            filename: [{ value: this.doc.filename, disabled: true }],
        });
        this.breakpoint = window.innerWidth <= 600 ? 1 : 2;
    }

    public onSave(): void {
        if (this.docForm.get('filename').value) {
            this.vendorService.updateVendorDoc(this.doc.docid,
                this.docForm.get('doc_name').value,
                this.docForm.get('doc_no').value,
                this.docForm.get('start_date').value,
                this.docForm.get('end_date').value,
                this.docForm.get('notaris').value,
                this.docForm.get('description').value,
                this.fileToUpload).subscribe(
                    respon => {
                        if (respon) {
                            this.dialogRef.close(true);
                        }
                    },
                    error => {
                        if (Utilities.checkNoNetwork(error)) {
                            swal.fire({
                                title: 'Save Vendor Document Failed!',
                                text: Utilities.noNetworkMessageDetail,
                                icon: 'error',
                                toast: false,
                                allowOutsideClick: true
                            })
                        } else {
                            swal.fire({
                                title: 'Save Vendor Document Failed!',
                                text: Utilities.getResponseBody(error),
                                icon: 'error',
                                toast: false,
                                allowOutsideClick: true
                            })
                        }
                    });
        }
        else {
            swal.fire({
                title: 'Save Vendor Document Failed!',
                text: 'Please select file',
                icon: 'error',
                toast: false,
                allowOutsideClick: true
            });
        }
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    // tslint:disable-next-line:no-any
    public onResize(event: any): void {
        this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
    }

    formChanged() {
        this.wasFormChanged = true;
    }
}