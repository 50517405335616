import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class MasterService {
    options = {
        headers:  new HttpHeaders({
            'Content-Type': 'application/json'
        }),
        withCredentials : true
    };
    constructor(
        private http: HttpClient
    ) {
    }


    GetCountryCode() {
        return this.http.get<any>(`/api/v1/master/get/countrycode`, this.options)
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    GetDueDillegentTemplate() {
        return this.http.get<any>(`/api/v1/master/duedillegent/template/get`, this.options)
            .pipe(
                map((res) => {
                    return res;
                }));
    }
}