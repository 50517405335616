import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PdfgeneratorService } from 'src/app/_services/pdf.service';
import { LoadingService } from '../_helpers/loading.service';
import { VendorService } from '../_services/vendor.service';

@Component({
  selector: 'app-home-user',
  templateUrl: './home.user.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeUserComponent implements OnInit {
  found: Boolean = false;
  vendorid: string;
  vendor_info: any;
  vendor_doc: any;
  vendor_history: any;
  vInfo: any;
  vDoc: any;
  vBusiness: any;

  constructor(
    private vendorService: VendorService,
    private pdfService: PdfgeneratorService,
    private router: Router,
    private readonly loadingService: LoadingService) {

  }
  ngOnInit(): void {
    this.loadVendorInformation();
  }

  loadVendorInformation() {
    this.vendorService.getVendorUser().subscribe(
      respon => {
        if (respon) {
          this.vendor_info = respon;
          this.vendorid = respon.objectId;
          this.found = true;
          this.loadVendorDoc();
          this.loadHistory();
        }

      },
      error => {
        console.error(error)
      });
  }

  loadVendorDoc() {
    if (this.vendorid) {
      this.vendorService.getRecentExpVendorDocById(this.vendorid).subscribe(
        respon => {
          if (respon) {
            this.vendor_doc = respon;
          }
        },
        error => {
          console.error(error)
        });
    }

  }

  loadHistory() {
    if (this.vendorid) {
      this.vendorService.getAllVendorHistoriesByReqVendorid(this.vendorid).subscribe(
        respon => {
          this.vendor_history = respon;
        },
        error => {
          console.error(error)
        });
    }
  }

  onCreate() {
    this.router.navigate(['/vendor']);
  }


  loadInfo(vendorid) {
    this.vendorService.getVendorInfoById(vendorid).subscribe(
      respon => {
        this.vInfo = respon;
      },
      error => {
        console.error(error)
      });
  }

  loadBusinnes(vendorid) {
    this.vendorService.getBusineessVendor(vendorid).subscribe(
      respon => {
        this.vBusiness = respon;
      },
      error => {
        console.error(error)
      });
  }

  loadDocument(vendorid) {
    this.vendorService.getAllVendorDocByReqVendorid(vendorid).subscribe(
      respon => {
        this.vDoc = respon;
      },
      error => {
        console.error(error)
      });
  }

  loadData(vendorid: string) {
    this.loadInfo(vendorid);
    this.loadBusinnes(vendorid);
    this.loadDocument(vendorid);
  }

  async onPrint() {
    this.vInfo = null;
    this.vBusiness = null;
    this.vDoc = null;
    if (this.vendorid) {
      this.vendorService.getVendorInfoById(this.vendorid).subscribe(
        respon => {
          this.vInfo = respon;
          this.vendorService.getBusineessVendor(this.vendorid).subscribe(
            respon => {
              this.vBusiness = respon;
              this.vendorService.getAllVendorDocByReqVendorid(this.vendorid).subscribe(
                respon => {
                  this.vDoc = respon;
                  this.loadingService.show();
                  setTimeout(() => {
                    if (this.vInfo && this.vDoc && this.vBusiness) {
                      this.pdfService.generatePdfUrl(this.vInfo, this.vDoc, this.vBusiness);
                      this.loadingService.hide();
                    }
                    else {
                      this.loadingService.hide();
                    }
                  }, 1000);
                },
                error => {
                  console.error(error)
                });
            },
            error => {
              console.error(error)
            });
        },
        error => {
          console.error(error)
        });

    }
  }

  onDownLoad(docid) {
    if (docid) {
      this.vendorService.downloadDoc(docid).subscribe(
        respon => {
          var fileURL = URL.createObjectURL(respon);
          window.open(fileURL);
        },
        error => {
          console.error(error)
        });
    }
  }
}
