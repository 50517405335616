import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Utilities } from 'src/app/_helpers/utilities';
import { RegisterModel } from 'src/app/_models/register-model';
import { MasterService } from 'src/app/_services/master.service';
import { UserService } from 'src/app/_services/user.service';
import swal from 'sweetalert2';

@Component({
    selector: 'app-user-add',
    templateUrl: './user-add.component.html',
    styleUrls: ['./user-add.component.scss']
})

export class UserAddComponent implements OnInit {
    isLoading = false;
    registerForm: FormGroup;
    submitted = false;
    roles = [];
    countryCodes: any;

    constructor(
        private masterService: MasterService,
        private router: Router,
        private userService: UserService,
        private formBuilder: FormBuilder) {

    }

    ngOnInit() {
        this.loadCountryCode();
        this.loadRoles();
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    get rolesFormArray() {
        return this.registerForm.controls.roleusers as FormArray;
    }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid

        console.log(this.f.phone.errors)
        if (this.registerForm.invalid) {
            return;
        }

        this.isLoading = true;

        const registerModel: RegisterModel = {
            address: '',
            email: this.registerForm.controls.email.value,
            firstname: this.registerForm.controls.firstName.value,
            gender: this.registerForm.controls.gender.value,
            lastname: this.registerForm.controls.lastName.value,
            phone: this.registerForm.controls.phone.value,
            roles: this.registerForm.controls.roleusers.value,
        }

        this.userService.insertUser(registerModel).pipe()
            .subscribe(
                (res) => {
                    swal.fire({
                        title: 'Add New user Success!',
                        text: res,
                        icon: 'success',
                        toast: false,
                        allowOutsideClick: false,
                        backdrop: true,
                        confirmButtonText: 'Goto User List'
                    }).then(_ => {
                        this.router.navigate(['/user']);
                    })
                },
                (error) => {
                    if (Utilities.checkNoNetwork(error)) {
                        swal.fire({
                            title: 'Add New user Failed!',
                            text: Utilities.noNetworkMessageDetail,
                            icon: 'error',
                            toast: false,
                            allowOutsideClick: true
                        })
                    } else {
                        swal.fire({
                            title: 'Add New user Failed!',
                            text: Utilities.getResponseBody(error),
                            icon: 'error',
                            toast: false,
                            allowOutsideClick: true
                        })
                    }
                });
    }

    onCheckboxChange(e) {
        const roless: FormArray = this.registerForm.get('roleusers') as FormArray;

        if (e.target.checked) {
            roless.push(new FormControl(e.target.value));
        } else {
            const index = roless.controls.findIndex(x => x.value === e.target.value);
            roless.removeAt(index);
        }
    }

    loadRoles() {
        this.userService.getAllRoles().subscribe(
            respon => {

                if (respon) {
                    respon.forEach(element => {
                        let role = {
                            id: element.objectId,
                            name: element.roleName.replace('_', ' ')
                        }
                        this.roles.push(role);

                    });
                    this.createForm();
                }
            },
            error => {
                console.error(error)
            });
    }

    createForm() {
        setTimeout(() => {
            this.registerForm = this.formBuilder.group({
                firstName: ['', Validators.required],
                lastName: [''],
                email: ['', [Validators.required, Validators.email]],
                gender: ['Laki-Laki'],
                phone: ['', Validators.required],
                phone_cc: ['+62'],
                phone_dc: [{ value: '+62', disabled: true }],
                phone_ac: ['', Validators.required],
                phone_n: ['', Validators.required],
                roleusers: this.formBuilder.array([], [Validators.required])
            });
        });
    }

    onChangePhone(e) {
        if (e.target.name === 'phone_cc') {
            this.f.phone_dc.setValue(e.target.value);
        }

        if (this.f.phone_dc.value && this.f.phone_ac.value && this.f.phone_n.value) {
            this.f.phone.setValue(`${this.f.phone_dc.value}-${this.f.phone_ac.value}-${this.f.phone_n.value}`);
        }
    }

    onReset() {
        this.submitted = false;
        this.registerForm.reset();
    }

    onCancel() {
        this.router.navigate(['/user']);
    }

    loadCountryCode() {
        this.masterService.GetCountryCode()
            .subscribe(
                respon => {
                    this.countryCodes = respon;
                },
                error => {
                    console.error(error)
                }
            );
    }

}
