import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../_helpers/animations';
import { TokenStorage } from '../_helpers/token-storage';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [fadeInOut]

})
export class HomeComponent implements OnInit {
  type_dashbord: number = 0;

  constructor(private tokenStorage: TokenStorage) {

  }

  ngOnInit(): void {
    this.loadDashboard();
  }

  loadDashboard() {
    if (this.isShowUserVendor)
      this.type_dashbord = 1;
    else if (this.isShowVendorManagement)
      this.type_dashbord = 2;
    else if (this.isShowTeamManager)
      this.type_dashbord = 3;
    else if (this.isShowAdmin)
      this.type_dashbord = 4;
  }

  get isAuthorized() {
    return this.tokenStorage.getToken() != null;
  }

  get isShowUserVendor() {
    if (this.tokenStorage.getToken() != null) {
      const roles = [];
      const tokeninfo = this.tokenStorage.getTokenInfo();
      let rols = tokeninfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      if (rols instanceof Array) {
        rols.forEach(r => {
          roles.push(r);
        });
      } else {
        roles.push(rols);
      }
      return roles.some(r => r === "ROLE_VENDOR");
    }
    return false;
  }

  get isShowVendorManagement() {
    if (this.tokenStorage.getToken() != null) {
      const roles = [];
      const tokeninfo = this.tokenStorage.getTokenInfo();
      let rols = tokeninfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      if (rols instanceof Array) {
        rols.forEach(r => {
          roles.push(r);
        });
      } else {
        roles.push(rols);
      }
      return roles.some(r => r === "ROLE_VENDOR_MANAGEMENT");
    }
    return false;
  }

  get isShowTeamManager() {
    if (this.tokenStorage.getToken() != null) {
      const roles = [];
      const tokeninfo = this.tokenStorage.getTokenInfo();
      let rols = tokeninfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      if (rols instanceof Array) {
        rols.forEach(r => {
          roles.push(r);
        });
      } else {
        roles.push(rols);
      }
      return roles.some(r => r === "ROLE_VM_APPROVER"); //ROLE_TEAM_MANAGER
    }
    return false;
  }

  get isShowAdmin() {
    if (this.tokenStorage.getToken() != null) {
      const roles = [];
      const tokeninfo = this.tokenStorage.getTokenInfo();
      const rols = tokeninfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      if (rols instanceof Array) {
        rols.forEach(r => {
          roles.push(r);
        });
      } else {
        roles.push(rols);
      }
      return roles.some(r => r === "ROLE_ADMIN");
    }
    return false;
  }
}
