import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CreateVendorModel } from '../_models/create-vendor';
import { UpdateVendorModel } from '../_models/update-vendor';
import { DataResponse } from '../_models/data-response';


@Injectable({ providedIn: 'root' })
export class VendorService {
    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        }),
        withCredentials: true
    };
    constructor(
        private router: Router,
        private http: HttpClient
    ) {
    }

    getAllWithPaging(search: string, order: string, page: number, max: number) {

        return this.http.get<any>(`/api/v1/vendor/search?search=${search}&order=${order}&page=${page}&max=${max}`, this.options)
            .pipe(
                map((res: DataResponse) => {
                    return res;
                }));
    }

    getAllWithRolePaging(search: string, order: string, page: number, max: number) {

        return this.http.get<any>(`/api/v1/vendor/role/search?search=${search}&order=${order}&page=${page}&max=${max}`, this.options)
            .pipe(
                map((res: DataResponse) => {
                    return res;
                }));
    }

    downloadDoc(docid: string): any {
        return this.http.get(`/api/v1/vendor/doc/file/${docid}`, { responseType: 'blob' })
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    downloadBusineesDoc(type: string, businessid: string): any {
        return this.http.get(`/api/v1/vendor/doc/business/${type}/${businessid}`, { responseType: 'blob' })
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    getVendorUser() {
        return this.http.get<any>(`/api/v1/vendor/get/current`, this.options)
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    getVendorInfoById(vendorid: string) {
        return this.http.get<any>(`/api/v1/vendor/get/${vendorid}`, this.options)
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    getSanksiByVendorId(vendorid: string) {
        return this.http.get<any>(`/api/v1/vendor/sanksi/get/${vendorid}`, this.options)
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    getRecentExpVendorDocById(vendorid: string) {
        return this.http.get<any>(`/api/v1/vendor/doc/exp/${vendorid}`, this.options)
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    getDashboardCount() {
        return this.http.get<any>(`/api/v1/vendor/dasboard/getcount`, this.options)
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    getDashboardVendor(tipe: number) {
        return this.http.get<any>(`/api/v1/vendor/dasboard/getvendor/${tipe}`, this.options)
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    getDashboardRecentDoc() {
        return this.http.get<any>(`/api/v1/vendor/dasboard/getdoc/`, this.options)
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    getBusineessVendor(vendorid: string) {
        return this.http.get<any>(`/api/v1/vendor/business/get/${vendorid}`, this.options)
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    getDueVendor(vendorid: string) {
        return this.http.get<any>(`/api/v1/vendor/due/get/${vendorid}`, this.options)
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    getAllVendorDocByVendorid(vendorid: string) {
        return this.http.get<any>(`/api/v1/vendor/doc/get/${vendorid}`, this.options)
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    getAllVendorDocByReqVendorid(vendorid: string) {
        return this.http.get<any>(`/api/v1/vendor/doc/getbyreq/${vendorid}`, this.options)
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    getAllVendorHistoriesByReqVendorid(vendorid: string) {
        return this.http.get<any>(`/api/v1/vendor/history/get/${vendorid}`, this.options)
            .pipe(
                map((res) => {
                    return res;
                }));
    }

    insertVendor(vendor: CreateVendorModel) {
        return this.http.post<any>('/api/v1/vendor/information/create', vendor, this.options)
            .pipe(map((res: string) => {
                return res;
            }));
    }

    postVendorSanksi(sanksi: any) {
        return this.http.post<any>('/api/v1/vendor/sanksi/post', sanksi, this.options)
            .pipe(map((res: string) => {
                return res;
            }));
    }

    updateVendor(vendor: UpdateVendorModel) {
        return this.http.post<any>('/api/v1/vendor/information/update', vendor, this.options)
            .pipe(map((res: string) => {
                return res;
            }));
    }

    updateVendorDue(vendor: any) {
        return this.http.post<any>('/api/v1/vendor/due/create/draft', vendor, this.options)
            .pipe(map((res: string) => {
                return res;
            }));
    }

    submitVendorDue(vendor: any) {
        return this.http.post<any>('/api/v1/vendor/due/create/submit', vendor, this.options)
            .pipe(map((res: string) => {
                return res;
            }));
    }

    submitVendor(vendor: any) {
        return this.http.post<any>('/api/v1/vendor/submit', vendor, this.options)
            .pipe(map((res: string) => {
                return res;
            }));
    }

    reviewVendor(vendor: any) {
        return this.http.post<any>('/api/v1/vendor/review', vendor, this.options)
            .pipe(map((res: string) => {
                return res;
            }));
    }

    reviewRejectedVendor(vendor: any) {
        return this.http.post<any>('/api/v1/vendor/notreview', vendor, this.options)
            .pipe(map((res: string) => {
                return res;
            }));
    }

    approveVendor(vendor: any) {
        return this.http.post<any>('/api/v1/vendor/approve', vendor, this.options)
            .pipe(map((res: any) => {
                return res;
            }));
    }

    activeinactiveVendor(action: number, vendor: any) {
        if (action == 0) {
            return this.http.post<any>('/api/v1/vendor/inactive', vendor, this.options)
                .pipe(map((res: any) => {
                    return res;
                }))
        }
        else {
            return this.http.post<any>('/api/v1/vendor/active', vendor, this.options)
                .pipe(map((res: any) => {
                    return res;
                }));
        }
        ;
    }

    approveVendorMail(vendor: any) {
        return this.http.post<any>('/api/v1/vendor/approvemail', vendor, this.options)
            .pipe(map((res: string) => {
                return res;
            }));
    }

    approveRejectedVendor(vendor: any) {
        return this.http.post<any>('/api/v1/vendor/notapprove', vendor, this.options)
            .pipe(map((res: string) => {
                return res;
            }));
    }

    updateVendorDoc(docid: string, name: string, no: string, startdate: string, enddate: string, notaris: string, description: string, file: File) {
        let formData: FormData = new FormData();
        formData.append('docid', docid);
        formData.append('docname', name);
        formData.append('docno', no);
        formData.append('startdate', new Date(startdate).toISOString());
        if (enddate)
            formData.append('enddate', new Date(enddate).toISOString());

        formData.append('notaris', notaris);
        formData.append('desc', description);
        formData.append('file', file);


        return this.http.post<any>('/api/v1/vendor/doc/upload', formData)
            .pipe(map((res: string) => {
                return res;
            }));
    }

    deleteVendorDoc(docid: string): any {
      return this.http.delete<any>(`/api/v1/vendor/doc/delete/${docid}`)
        .pipe(map((res: string) => {
          return res;
        }));
    }


    approveVendorFile(vendor: any) {
        return this.http.post<any>('/api/v1/vendor/approve', vendor, this.options)
            .pipe(map((res: string) => {
                return res;
            }));
    }

    saveAsDrafBusiness(businessid: string, vendorid: string, type: string, field: string, director: string, commissioner: string, shareholder: string,
        sistercompany: string, isitem: any, isservice: any, itemfile: File, serviceFile: File) {
        let formData: FormData = new FormData();
        if (businessid)
            formData.append('businessid', businessid);

        formData.append('vendorid', vendorid);
        formData.append('type', type);
        formData.append('field', field);
        formData.append('director', director);
        formData.append('commissioner', commissioner);
        formData.append('shareholder', shareholder);
        formData.append('sistercompany', sistercompany);
        formData.append('isitem', isitem);
        formData.append('isservice', isservice);
        formData.append('item', itemfile);
        formData.append('service', serviceFile);


        return this.http.post<any>('/api/v1/vendor/doc/update/draf', formData)
            .pipe(map((res: string) => {
                return res;
            }));
    }

    submitBusiness(businessid: string, vendorid: string, type: string, field: string, director: string, commissioner: string, shareholder: string,
        sistercompany: string, isitem: any, isservice: any, itemfile: File, serviceFile: File) {
        let formData: FormData = new FormData();
        if (businessid)
            formData.append('businessid', businessid);

        formData.append('vendorid', vendorid);
        formData.append('type', type);
        formData.append('field', field);
        formData.append('director', director);
        formData.append('commissioner', commissioner);
        formData.append('shareholder', shareholder);
        formData.append('sistercompany', sistercompany ?? '');
        formData.append('isitem', isitem);
        formData.append('isservice', isservice);
        formData.append('item', itemfile);
        formData.append('service', serviceFile);


        return this.http.post<any>('/api/v1/vendor/doc/update/submit', formData)
            .pipe(map((res: string) => {
                return res;
            }));
    }

    reviewEditInfo(vendorid: string, vtype: string, vsap: string, vname: string, vhoaddress: string, vhotlpn: string, vhofax: string, vhoemail1: string, vhoemail2: string,
        vhocp1: string, vhocp2: string, vhophone1: string, vhophone2: string, vboadress: string, vbotlpn: string, vbofax: string, vboemail1: string, vboemail2: string,
        vbocp1: string, vbocp2: string, vbophone1: string, vbophone2: string, type: string, field: string, director: string, commissioner: string, shareholder: string,
        sistercompany: string, isitem: any, isservice: any, itemfile: File, serviceFile: File) {
        let formData: FormData = new FormData();

        formData.append('vendor_id', vendorid);
        formData.append('vendor_type', vtype);
        formData.append('vendor_sap', vsap);
        formData.append('vendor_name', vname);
        formData.append('vendor_address_p', vhoaddress);
        formData.append('vendor_tlpn_p', vhotlpn);
        formData.append('vendor_fax_p', vhofax);
        formData.append('vendor_emai1_p', vhoemail1);
        formData.append('vendor_emai2_p', vhoemail2);
        formData.append('vendor_cp1_p', vhocp1);
        formData.append('vendor_cp2_p', vhocp2);
        formData.append('vendor_phone1_p', vhophone1);
        formData.append('vendor_phone2_p', vhophone2);
        formData.append('vendor_address_c', vboadress);
        formData.append('vendor_tlpn_c', vbotlpn);
        formData.append('vendor_fax_c', vbofax);
        formData.append('vendor_email1_c', vboemail1);
        formData.append('vendor_email2_c', vboemail2);
        formData.append('vendor_cp1_c', vbocp1);
        formData.append('vendor_cp2_c', vbocp2);
        formData.append('vendor_phone1_c', vbophone1);
        formData.append('vendor_phone2_c', vbophone2);
        formData.append('type', type);
        formData.append('field', field);
        formData.append('director', director);
        formData.append('commissioner', commissioner);
        formData.append('shareholder', shareholder);
        formData.append('sistercompany', sistercompany);
        formData.append('isitem', isitem);
        formData.append('isservice', isservice);
        formData.append('item', itemfile);
        formData.append('service', serviceFile);

        return this.http.post<any>('/api/v1/vendor/review/info/edit', formData)
            .pipe(map((res: string) => {
                return res;
            }));
    }

    reviewEditDue(vendor: any) {
        return this.http.post<any>('/api/v1/vendor/review/due/edit', vendor, this.options)
            .pipe(map((res: string) => {
                return res;
            }));
    }

    exportToexcel(search: string, order: string): any {
        return this.http.get(`/api/v1/vendor/export?search=${search}&order=${order}`, { responseType: 'blob' })
            .pipe(
                map((res) => {
                    return res;
                }));
    }
}
