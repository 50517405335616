import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UserLogin } from '../_models/user-login.model';
import { TokenStorage } from '../_helpers/token-storage';


@Injectable({ providedIn: 'root' })
export class AuthService {
    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        }),
        withCredentials: true
    };
    public loginRedirectUrl: string;
    public logoutRedirectUrl: string;

    constructor(
        private router: Router,
        private http: HttpClient,
        private tokenStorage: TokenStorage,
    ) {
    }

    getCaptchaInfo() {
        return this.http.get<any>(`/api/v1/auth/captcha`, { withCredentials: true })
            .pipe(map(res => {
                return res;
            }));
    }

    login(userlogin: UserLogin, attempt: string) {
        let enc = btoa(`${userlogin.username}:${userlogin.password}`)
        const headers = new HttpHeaders({ "Content-Type": "application/x-www-form-urlencoded" });

        var urlencoded = new URLSearchParams();
        urlencoded.append("Encript", enc);
        urlencoded.append("Attempt", attempt);
        urlencoded.append("DNTCaptchaInputText", userlogin.DNTCaptchaInputText);
        urlencoded.append("DNTCaptchaToken", userlogin.DNTCaptchaToken);
        urlencoded.append("DNTCaptchaText", userlogin.DNTCaptchaText);


        return this.http.post<any>(`/api/v1/auth/login`, urlencoded.toString(), { headers, withCredentials: true })
            .pipe(map(user => {
                this.tokenStorage.saveToken(user.access_token, user.refresh_token);
                //delay(500);
                this.startRefreshTokenTimer();
                return user;
            }));
    }

    logout() {
        this.http.post<any>(`/api/v1/auth/logout`, {}, this.options).subscribe();
        this.tokenStorage.clearToken();
        this.stopRefreshTokenTimer();
        this.router.navigate(['/login']);
    }

    refreshToken() {
        let refresh = this.tokenStorage.getRefreshToken();
        return this.http.post<any>(`/api/v1/auth/refresh-token`, { refreshToken: refresh }, this.options)
            .pipe(map((user) => {
                this.tokenStorage.saveToken(user.access_token, user.refresh_token);
                this.startRefreshTokenTimer();
                return user;
            }));
    }

    // helper methods

    private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.tokenStorage.getToken().split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (5 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

}