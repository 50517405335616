import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TokenStorage } from './token-storage';
import { AuthService } from '../_services/auth.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private tokenStorage: TokenStorage, private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(catchError(err => {
            if ([401, 403].includes(err.status) && this.tokenStorage.getToken()) {
                this.authService.logout();
            }
            if ([200, 201].includes(err.status)) {
                return;
            }
            return throwError(err);
        }))
    }
}