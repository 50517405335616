import { Injectable, ɵConsole } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DataResponse } from '../_models/data-response';
import { RegisterModel } from '../_models/register-model';
import { UpdateUserModel } from '../_models/update-user-model';


@Injectable({ providedIn: 'root' })
export class UserService {
    options = {
        headers:  new HttpHeaders({
            'Content-Type': 'application/json'
        }),
        withCredentials : true
    };
    constructor(
        private router: Router,
        private http: HttpClient
    ) {
    }


    getAllWithPaging(search: string, order: string, page: number, max: number) {
       
        return this.http.get<any>(`/api/v1/user/search?search=${search}&order=${order}&page=${page}&max=${max}`, this.options)
            .pipe(
            map((res: DataResponse) => {
                return res;
            }));
    }

    getDashboardCount() {
       
        return this.http.get<any>(`/api/v1/user/dasboard/getcount`, this.options)
            .pipe(
            map((res: DataResponse) => {
                return res;
            }));
    }

    getAllRoles(){
        return this.http.get<any>(`/api/v1/user/roles/all`,this.options)
            .pipe(
            map((res) => {
                return res;
            }));
    }

    getUserId(id:string){
        return this.http.get<any>(`/api/v1/user/getbyid/${id}`,this.options)
            .pipe(
            map((res) => {
                return res;
            }));
    }

    getUserLogin(){
        return this.http.get<any>(`/api/v1/user/getown`)
            .pipe(
            map((res) => {
                return res;
            }));
    }

    insertUser(user : RegisterModel){
        return this.http.post<any>('/api/v1/user/register', user, this.options) 
        .pipe(map((res:string) => {
            return res;
        }));
    }

    updateUserStatus(id:string, stat:boolean){
        return this.http.post<any>('/api/v1/user/updatestatus', { userid : id, status:stat}, this.options) 
        .pipe(map((res:string) => {
            return res;
        }));
    }

    updateUser(user: UpdateUserModel){
        return this.http.post<any>('/api/v1/user/update', user, this.options) 
        .pipe(map((res:string) => {
            return res;
        }));
    }

    updateUserNewPassword(id:string){
        return this.http.post<any>('/api/v1/user/generetepassword', { userid : id}, this.options) 
        .pipe(map((res:string) => {
            return res;
        }));
    }

    changePassword(chgpwd:any){
        return this.http.post<any>('/api/v1/user/chgpwd', chgpwd, this.options) 
        .pipe(map((res:string) => {
            return res;
        }));
    }

    updateProfile(profile:any){
        return this.http.post<any>('/api/v1/user/profile', profile, this.options) 
        .pipe(map((res:string) => {
            return res;
        }));
    }
}