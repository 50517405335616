import { OnInit, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { Utilities } from 'src/app/_helpers/utilities';
import { CreateVendorModel } from 'src/app/_models/create-vendor';
import { UpdateVendorModel } from 'src/app/_models/update-vendor';
import { MasterService } from 'src/app/_services/master.service';
import { VendorService } from 'src/app/_services/vendor.service';
import swal from 'sweetalert2';
import { AddVendorDocComponent } from '../add-vendordoc/add-vendordoc.component';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})
export class VendorComponent implements OnInit {
  isEdit: boolean = true;
  clickEdit: boolean = false;
  submitCreate: string = "Create";
  status: string = 'DRAF';
  isLoadFinish: Boolean = false;
  vendorid: string;
  businessid: string;
  isLinear = true;
  submitted = false;
  submiteddone = false;
  doc_submitted = false;
  vInformation: FormGroup;
  vDocument: FormGroup;
  vDueDiligent: FormGroup;
  vSubmit: FormGroup;
  vInformationisEditable = true;
  vDocumentisEditable = false;
  vDueDiligentisEditable = false;
  vSubmitisEditable = false;
  vInformationisComplate = false;
  vDocumentisComplate = false;
  vDueDiligentisComplate = false;
  vSubmitisComplate = false;
  step: number;
  vendor_info: any;
  vendor_doc: any;
  vendor_businees: any;
  vendor_due: any;
  stepIndex = 0;
  docSource: any;
  displayedColumns = ['DocName', 'No', 'StartDate', 'EndDate', 'Note', 'Action'];
  countryCodes: any;
  due_dillegent_templates: any;
  serviceToUpload: File = null;
  itemToUpload: File = null;
  remark = "";
  historySource: any;
  historydisplayedColumns = ['Name', 'Action', 'Activity', 'Date', 'Remark'];

  @ViewChild('stepper') private myStepper: MatStepper;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private vendorService: VendorService,
    private masterService: MasterService,
    private _formBuilder: FormBuilder
  ) {

  }
  ngOnInit(): void {
    this.loadCountryCode();
    this.loadVendorInformation();
    this.createFormVendorSubmit();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AddVendorDocComponent, {
      width: '70%', disableClose: true
    });
  }

  onEdit(row) {
    if (row) {
      let docData = {
        docid: row.objectId,
        doc_name: row.docName,
        doc_no: row.number,
        start_date: row.startDate ?? new Date(),
        end_date: row.endDate,
        notaris: row.notaris,
        description: row.description,
        filename: row.fileName,
      };

      const dialogRef = this.dialog.open(AddVendorDocComponent, {
        data: docData,
        width: "100vw",
        height: "100vh",
        maxWidth: "60vw",
        maxHeight: "70vh",
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.loadDoc();
        }
      });
    }
  }

  onDelete(row) {

    swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this document?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (row) {
          this.vendorService.deleteVendorDoc(row.objectId)
            .subscribe(
              respon => {
                swal.fire({
                  title: "Deleted!",
                  text: "Your document has been deleted.",
                  icon: "success"
                });

                this.loadDoc();
              },
              error => {
                console.error(error)
              }
            );
        }
      }
    });

    
  }

  onDownLoad(row) {
    if (row) {
      this.vendorService.downloadDoc(row.objectId).subscribe(
        respon => {
          var fileURL = URL.createObjectURL(respon);
          window.open(fileURL);
        },
        error => {
          console.error(error)
        });
    }
  }

  get f_info() { return this.vInformation.controls; }
  get f_doc() { return this.vDocument.controls; }
  get f_due() { return this.vDueDiligent.controls; }
  get f_sub() { return this.vSubmit.controls; }



  createFormVendorInformation() {
    let vendorType = 'PT', vendorName = '', headOfficeAddress = '', hoNoTlpn = '', hoFax = '', hoEmail1 = '',
      hoEmail2 = '', hoContactPerson1 = '', hoContactPerson2 = '', hoPhone1 = '', hoPhone2 = '', branchOfficeAddress = '',
      boNoTlpn = '', boFax = '', boEmail1 = '', boEmail2 = '', boContactPerson1 = '', boContactPerson2 = '', boPhone1 = '', boPhone2 = '',
      hoNoTlpn_cc = '+62', hoNoTlpn_dc = '+62', hoNoTlpn_ac = '', hoNoTlpn_n = '', hoFax_cc = '+62', hoFax_dc = '+62', hoFax_ac = '', hoFax_n = '',
      hoPhone1_cc = '+62', hoPhone1_dc = '+62', hoPhone1_ac = '', hoPhone1_n = '', hoPhone2_cc = '+62', hoPhone2_dc = '+62', hoPhone2_ac = '', hoPhone2_n = '',
      boNoTlpn_cc = '+62', boNoTlpn_dc = '+62', boNoTlpn_ac = '', boNoTlpn_n = '', boFax_cc = '+62', boFax_dc = '+62', boFax_ac = '', boFax_n = '',
      boPhone1_cc = '+62', boPhone1_dc = '+62', boPhone1_ac = '', boPhone1_n = '', boPhone2_cc = '+62', boPhone2_dc = '+62', boPhone2_ac = '', boPhone2_n = '';
    if (this.vendor_info) {
      this.vInformationisComplate = true;
      this.vInformationisEditable = true;
      vendorType = this.vendor_info.vendorType;
      vendorName = this.vendor_info.vendorName;
      headOfficeAddress = this.vendor_info.headOfficeAddress;
      hoNoTlpn = this.vendor_info.hoNoTlpn;
      if (this.vendor_info.hoNoTlpn) {
        let hoNoTlpnA = this.vendor_info.hoNoTlpn.split('-');
        hoNoTlpn_cc = hoNoTlpnA[0];
        hoNoTlpn_dc = hoNoTlpnA[0];
        hoNoTlpn_ac = hoNoTlpnA[1];
        hoNoTlpn_n = hoNoTlpnA[2];
      }
      hoFax = this.vendor_info.hoFax;
      if (this.vendor_info.hoFax) {
        let hoFaxA = this.vendor_info.hoFax.split('-');
        hoFax_cc = hoFaxA[0];
        hoFax_dc = hoFaxA[0];
        hoFax_ac = hoFaxA[1];
        hoFax_n = hoFaxA[2];
      }
      hoEmail1 = this.vendor_info.hoEmail1;
      hoEmail2 = this.vendor_info.hoEmail2;
      hoContactPerson1 = this.vendor_info.hoContactPerson1;
      hoContactPerson2 = this.vendor_info.hoContactPerson2;
      hoPhone1 = this.vendor_info.hoPhone1;
      if (this.vendor_info.hoPhone1) {
        let hoPhone1A = this.vendor_info.hoPhone1.split('-');
        hoPhone1_cc = hoPhone1A[0];
        hoPhone1_dc = hoPhone1A[0];
        hoPhone1_ac = hoPhone1A[1];
        hoPhone1_n = hoPhone1A[2];
      }
      hoPhone2 = this.vendor_info.hoPhone2;
      if (this.vendor_info.hoPhone2) {
        let hoPhone2A = this.vendor_info.hoPhone2.split('-');
        hoPhone2_cc = hoPhone2A[0];
        hoPhone2_dc = hoPhone2A[0];
        hoPhone2_ac = hoPhone2A[1];
        hoPhone2_n = hoPhone2A[2];
      }
      branchOfficeAddress = this.vendor_info.branchOfficeAddress;
      boNoTlpn = this.vendor_info.boNoTlpn;
      if (this.vendor_info.boNoTlpn) {
        let boNoTlpnA = this.vendor_info.boNoTlpn.split('-');
        boNoTlpn_cc = boNoTlpnA[0];
        boNoTlpn_dc = boNoTlpnA[0];
        boNoTlpn_ac = boNoTlpnA[1];
        boNoTlpn_n = boNoTlpnA[2];
      }
      boFax = this.vendor_info.boFax;
      if (this.vendor_info.boFax) {
        let boFaxA = this.vendor_info.boFax.split('-');
        boFax_cc = boFaxA[0];
        boFax_dc = boFaxA[0];
        boFax_ac = boFaxA[1];
        boFax_n = boFaxA[2];
      }
      boEmail1 = this.vendor_info.boEmail1;
      boEmail2 = this.vendor_info.boEmail2;
      boContactPerson1 = this.vendor_info.boContactPerson1;
      boContactPerson2 = this.vendor_info.boContactPerson2;
      boPhone1 = this.vendor_info.boPhone1;
      if (this.vendor_info.boPhone1) {
        let boPhone1A = this.vendor_info.boPhone1.split('-');
        boPhone1_cc = boPhone1A[0];
        boPhone1_dc = boPhone1A[0];
        boPhone1_ac = boPhone1A[1];
        boPhone1_n = boPhone1A[2];
      }
      boPhone2 = this.vendor_info.boPhone2;
      if (this.vendor_info.boPhone2) {
        let boPhone2A = this.vendor_info.boPhone2.split('-');
        boPhone2_cc = boPhone2A[0];
        boPhone2_dc = boPhone2A[0];
        boPhone2_ac = boPhone2A[1];
        boPhone2_n = boPhone2A[2];
      }
    }

    setTimeout(() => {
      this.vInformation = this._formBuilder.group({
        vendor_type: [vendorType],
        vendor_name: [vendorName, Validators.required],
        vendor_address_p: [headOfficeAddress, Validators.required],
        vendor_tlpn_p: [hoNoTlpn, Validators.required],
        ho_tlpn_cc: [hoNoTlpn_cc],
        ho_tlpn_dc: [{ value: hoNoTlpn_dc, disabled: true }],
        ho_tlpn_ac: [hoNoTlpn_ac, Validators.required],
        ho_tlpn_n: [hoNoTlpn_n, Validators.required],
        vendor_fax_p: [hoFax, Validators.required],
        ho_fax_cc: [hoFax_cc],
        ho_fax_dc: [{ value: hoFax_dc, disabled: true }],
        ho_fax_ac: [hoFax_ac, Validators.required],
        ho_fax_n: [hoFax_n, Validators.required],
        vendor_emai1_p: [hoEmail1, [Validators.required, Validators.email]],
        vendor_emai2_p: [hoEmail2],
        vendor_cp1_p: [hoContactPerson1, Validators.required],
        vendor_cp2_p: [hoContactPerson2],
        vendor_phone1_p: [hoPhone1, [Validators.required]],
        ho_phone1_cc: [hoPhone1_cc],
        ho_phone1_dc: [{ value: hoPhone1_dc, disabled: true }],
        ho_phone1_ac: [hoPhone1_ac, Validators.required],
        ho_phone1_n: [hoPhone1_n, Validators.required],
        vendor_phone2_p: [hoPhone2],
        ho_phone2_cc: [hoPhone2_cc],
        ho_phone2_dc: [{ value: hoPhone2_dc, disabled: true }],
        ho_phone2_ac: [hoPhone2_ac],
        ho_phone2_n: [hoPhone2_n],
        vendor_address_c: [branchOfficeAddress],
        vendor_tlpn_c: [boNoTlpn],
        bo_tlpn_cc: [boNoTlpn_cc],
        bo_tlpn_dc: [{ value: boNoTlpn_dc, disabled: true }],
        bo_tlpn_ac: [boNoTlpn_ac],
        bo_tlpn_n: [boNoTlpn_n],
        vendor_fax_c: [boFax],
        bo_fax_cc: [boFax_cc],
        bo_fax_dc: [{ value: boFax_dc, disabled: true }],
        bo_fax_ac: [boFax_ac],
        bo_fax_n: [boFax_n],
        vendor_email1_c: [boEmail1],
        vendor_email2_c: [boEmail2],
        vendor_cp1_c: [boContactPerson1],
        vendor_cp2_c: [boContactPerson2],
        vendor_phone1_c: [boPhone1],
        bo_phone1_cc: [boPhone1_cc],
        bo_phone1_dc: [{ value: boPhone1_dc, disabled: true }],
        bo_phone1_ac: [boPhone1_ac],
        bo_phone1_n: [boPhone1_n],
        vendor_phone2_c: [boPhone2],
        bo_phone2_cc: [boPhone2_cc],
        bo_phone2_dc: [{ value: boPhone2_dc, disabled: true }],
        bo_phone2_ac: [boPhone2_ac],
        bo_phone2_n: [boPhone2_n],
      });

    });

  }

  setStep() {
    if (this.vendor_info) {
      this.step = this.vendor_info.step;
      if (this.vendor_info.step >= 0) {
        this.vInformationisComplate = true;
        this.vInformationisEditable = true;
      }

      if (this.vendor_info.step >= 1) {
        this.vDocumentisComplate = true;
        this.vDocumentisEditable = true;
      }

      if (this.vendor_info.step >= 2) {
        this.vDueDiligentisComplate = true;
        this.vDueDiligentisEditable = true;
      }

      if (this.vendor_info.step >= 3) {
        this.vSubmitisEditable = true;
        this.vSubmitisComplate = true;
      }

      setTimeout(() => {
        this.stepIndex = this.vendor_info.step - 1;
      }, 500);
    }
  }

  createFormVendorDocument() {
    let type = 'Kecil', field = '', director = '', commisioner = '', shareholder = '', scompany = '', isservice = false, isitem = false, sfname = '', ifname = '';
    if (this.vendor_businees) {
      this.businessid = this.vendor_businees.objectId;
      type = this.vendor_businees.typeofBusiness;
      field = this.vendor_businees.businessFields;
      director = this.vendor_businees.presidentDirector;
      commisioner = this.vendor_businees.presidentCommissioner;
      shareholder = this.vendor_businees.shareholders;
      scompany = this.vendor_businees.sisterCompany;
      isservice = this.vendor_businees.isService;
      isitem = this.vendor_businees.isItem;
      sfname = this.vendor_businees.fileNameService;
      ifname = this.vendor_businees.fileNameItem;
    }
    setTimeout(() => {
      this.vDocument = this._formBuilder.group({
        type_of_business: [type, Validators.required],
        business_fields: [field, Validators.required],
        president_director: [director, Validators.required],
        president_commisioner: [commisioner, Validators.required],
        shareholders: [shareholder, Validators.required],
        sister_company: [scompany],
        is_service: [isservice],
        is_item: [isitem],
        sfilename: [{ value: sfname, disabled: true }],
        ifilename: [{ value: ifname, disabled: true }]
      });
    });
  }

  createFormVendorSubmit() {
    setTimeout(() => {
      this.vSubmit = this._formBuilder.group({
        remark: [""]
      });
    });
  }

  createFormVendorDueDillegent() {
    if (this.vendorid) {
      let s_a = "", t_a = "";
      if (this.vendor_due.length > 3) {
        s_a = this.vendor_due.find(o => o.no === '1a').responseText;
        t_a = this.vendor_due.find(o => o.no === '3a').responseText;
      }
      this.vDueDiligent = this._formBuilder.group({
        satu_a: [s_a, Validators.required],
        dua_a: this._formBuilder.array([]),
        dua_b: this._formBuilder.array([]),
        tiga_a: [t_a, Validators.required],
      });
      this.initDuaA();
      this.initDuaB();
    }
  }

  addDuaA() {
    const c_dua_a = <FormArray>this.vDueDiligent.controls['dua_a'];
    let initA = this._formBuilder.group({
      name: [''],
      percentage: [0],
      place: [''],
    });
    c_dua_a.push(initA);
  }

  addDuaB() {
    const c_dua_b = <FormArray>this.vDueDiligent.controls['dua_b'];
    let initB = this._formBuilder.group({
      name: ['', Validators.required],
      company: ['', Validators.required],
      position: ['', Validators.required],
      base64: [null],
      filename: [''],
      ext: [''],
      contenttype: [''],
    });
    c_dua_b.push(initB);
  }

  removeDuaA(i) {
    const c_dua_a = <FormArray>this.vDueDiligent.controls['dua_a'];
    c_dua_a.removeAt(i);
  }

  removeDuaB(i) {
    const c_dua_b = <FormArray>this.vDueDiligent.controls['dua_b'];
    c_dua_b.removeAt(i);
  }

  initDuaA() {
    const c_dua_a = <FormArray>this.vDueDiligent.controls['dua_a'];
    if (this.vendor_due && this.vendor_due.length) {
      let d2as = this.vendor_due.find(o => o.no === '2a');
      if (d2as.details) {
        d2as.details.forEach(o2a => {
          let initA = this._formBuilder.group({
            name: [o2a.reponseColumn1],
            percentage: [o2a.reponseColumn2],
            place: [o2a.responseColumn3],
          });
          c_dua_a.push(initA);
        });
      }
      else {
        let initA = this._formBuilder.group({
          name: [''],
          percentage: [''],
          place: [''],
        });
        c_dua_a.push(initA);
      }
    }
    else {
      let initA = this._formBuilder.group({
        name: [''],
        percentage: [''],
        place: [''],
      });
      c_dua_a.push(initA);
    }
  }

  initDuaB() {
    const c_dua_b = <FormArray>this.vDueDiligent.controls['dua_b'];
    if (this.vendor_due && this.vendor_due.length) {
      let d2bs = this.vendor_due.find(o => o.no === '2b');
      if (d2bs.details && d2bs.details.length) {
        d2bs.details.forEach(o2b => {
          let initB = this._formBuilder.group({
            name: [o2b.reponseColumn1],
            company: [o2b.reponseColumn2],
            position: [o2b.responseColumn3],
            base64: [o2b.fileBase64],
            filename: [o2b.fileName],
            ext: [o2b.fileExtenstion],
            contenttype: [o2b.fileContentType],
          });
          c_dua_b.push(initB);
        });
      }
      else {
        let initB = this._formBuilder.group({
          name: ['', Validators.required],
          company: ['', Validators.required],
          position: ['', Validators.required],
          base64: [null],
          filename: [''],
          ext: [''],
          contenttype: [''],
        });
        c_dua_b.push(initB);
      }
    }
    else {
      let initB = this._formBuilder.group({
        name: ['', Validators.required],
        company: ['', Validators.required],
        position: ['', Validators.required],
        base64: [null],
        filename: [''],
        ext: [''],
        contenttype: [''],
      });
      c_dua_b.push(initB);
    }
  }

  onDocSaveDraft() {
    if (this.vendorid) {
      this.vendorService.saveAsDrafBusiness(this.businessid, this.vendorid, this.vDocument.controls.type_of_business.value, this.vDocument.controls.business_fields.value,
        this.vDocument.controls.president_director.value, this.vDocument.controls.president_commisioner.value, this.vDocument.controls.shareholders.value, this.vDocument.controls.sister_company.value,
        this.vDocument.controls.is_item.value, this.vDocument.controls.is_service.value, this.itemToUpload, this.serviceToUpload)
        .pipe()
        .subscribe(
          (res) => {
            swal.fire({
              title: 'Save Draf Document Success!',
              text: res,
              icon: 'success',
              toast: false,
              allowOutsideClick: false,
              backdrop: true,
              confirmButtonText: 'Ok'
            }).then(_ => {

            })
          },
          (error) => {
            if (Utilities.checkNoNetwork(error)) {
              swal.fire({
                title: 'Save Draf Document Failed!',
                text: Utilities.noNetworkMessageDetail,
                icon: 'error',
                toast: false,
                allowOutsideClick: true
              })
            } else {
              swal.fire({
                title: 'Save Draf Document Failed!',
                text: Utilities.getResponseBody(error),
                icon: 'error',
                toast: false,
                allowOutsideClick: true
              })
            }
          });
    }
  }

  onDueSaveDraft() {
    if (this.vendorid) {
      let dueDillegents1a = {
        no: '1a',
        type: "TEXT",
        response: this.vDueDiligent.controls.satu_a.value,
        details: []
      };

      let dueDillegents3a = {
        no: '3a',
        type: "TEXT",
        response: this.vDueDiligent.controls.tiga_a.value,
        details: []
      }

      let dueDillegents2a = {
        no: '2a',
        type: "DETAIL",
        response: '',
        details: []
      };
      this.vDueDiligent.controls.dua_a.value.forEach(element => {
        if (element.name) {
          let o2a = {
            colum1: element.name,
            colum2: element.percentage.toString(),
            colum3: element.place,
          }
          dueDillegents2a.details.push(o2a);
        }
      });

      let dueDillegents2b = {
        no: '2b',
        type: "DETAIL",
        response: '',
        details: []
      };
      this.vDueDiligent.controls.dua_b.value.forEach(element => {
        if (element.name) {
          let o2b = {
            colum1: element.name,
            colum2: element.company,
            colum3: element.position,
            base64: element.base64,
            filename: element.filename,
            contenttype: element.contenttype,
            ext: element.ext
          }
          dueDillegents2b.details.push(o2b);
        }
      });


      const req = {
        vendorid: this.vendorid,
        dueDillegents: [dueDillegents1a, dueDillegents2a, dueDillegents2b, dueDillegents3a]
      }

      this.vendorService.updateVendorDue(req)
        .subscribe(
          (res) => {
            swal.fire({
              title: 'Save Draft Due Dillegent Success!',
              text: res,
              icon: 'success',
              toast: false,
              allowOutsideClick: false,
              backdrop: true,
              confirmButtonText: 'Ok'
            }).then(_ => {
              this.loadVendorInformation();
            })
          },
          (error) => {
            if (Utilities.checkNoNetwork(error)) {
              swal.fire({
                title: 'Save Draft Due Dillegent Failed!',
                text: Utilities.noNetworkMessageDetail,
                icon: 'error',
                toast: false,
                allowOutsideClick: true
              })
            } else {
              swal.fire({
                title: 'Save Draf Due Dillegent Failed!',
                text: Utilities.getResponseBody(error),
                icon: 'error',
                toast: false,
                allowOutsideClick: true
              })
            }
          });
    }
  }

  onDueSubmit() {
    if (this.vendorid) {
      let dueDillegents1a = {
        no: '1a',
        type: "TEXT",
        response: this.vDueDiligent.controls.satu_a.value,
        details: []
      };

      let dueDillegents3a = {
        no: '3a',
        type: "TEXT",
        response: this.vDueDiligent.controls.tiga_a.value,
        details: []
      }

      let dueDillegents2a = {
        no: '2a',
        type: "DETAIL",
        response: '',
        details: []
      };
      this.vDueDiligent.controls.dua_a.value.forEach(element => {
        if (element.name) {
          let o2a = {
            colum1: element.name,
            colum2: element.percentage.toString(),
            colum3: element.place,
          }
          dueDillegents2a.details.push(o2a);
        }
      });

      let dueDillegents2b = {
        no: '2b',
        type: "DETAIL",
        response: '',
        details: []
      };
      this.vDueDiligent.controls.dua_b.value.forEach(element => {
        if (element.name) {
          let o2b = {
            colum1: element.name,
            colum2: element.company,
            colum3: element.position,
            base64: element.base64,
            filename: element.filename,
            contenttype: element.contenttype,
            ext: element.ext
          }
          dueDillegents2b.details.push(o2b);
        }
      });


      const req = {
        vendorid: this.vendorid,
        dueDillegents: [dueDillegents1a, dueDillegents2a, dueDillegents2b, dueDillegents3a]
      }

      this.vendorService.submitVendorDue(req)
        .subscribe(
          (res) => {
            swal.fire({
              title: 'Submit Due Dilligent Success!',
              text: res,
              icon: 'success',
              toast: false,
              allowOutsideClick: false,
              backdrop: true,
              confirmButtonText: 'Go to next step'
            }).then(_ => {
              this.vSubmitisComplate = true;
              this.vSubmitisEditable = true;
              this.myStepper.next();
            })
          },
          (error) => {
            if (Utilities.checkNoNetwork(error)) {
              swal.fire({
                title: 'Submit Due Dilligent Failed!',
                text: Utilities.noNetworkMessageDetail,
                icon: 'error',
                toast: false,
                allowOutsideClick: true
              })
            } else {
              swal.fire({
                title: 'Submit Due Dilligent Failed!',
                text: Utilities.getResponseBody(error),
                icon: 'error',
                toast: false,
                allowOutsideClick: true
              })
            }
          });
    }
  }

  fileChanged(e, i) {
    const c_dua_b = <FormArray>this.vDueDiligent.controls['dua_b'];
    const reader = new FileReader();
    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        c_dua_b.at(i).get('base64').patchValue(reader.result);
        let fname = e.target.files[0].name.split('.');
        let ftype = e.target.files[0].type;
        c_dua_b.at(i).get('filename').patchValue(fname[0]);
        c_dua_b.at(i).get('ext').patchValue(fname[1]);
        c_dua_b.at(i).get('contenttype').patchValue(ftype);
      };
    }
  }

  onDocSubmit() {
    if (this.vendorid) {
      this.vendorService.submitBusiness(this.businessid, this.vendorid, this.vDocument.controls.type_of_business.value, this.vDocument.controls.business_fields.value,
        this.vDocument.controls.president_director.value, this.vDocument.controls.president_commisioner.value, this.vDocument.controls.shareholders.value, this.vDocument.controls.sister_company.value,
        this.vDocument.controls.is_item.value, this.vDocument.controls.is_service.value, this.itemToUpload, this.serviceToUpload)
        .pipe()
        .subscribe(
          (res) => {
            swal.fire({
              title: 'Submit Document Success!',
              text: res,
              icon: 'success',
              toast: false,
              allowOutsideClick: false,
              backdrop: true,
              confirmButtonText: 'Go to next step'
            }).then(_ => {
              this.vDueDiligentisComplate = true;
              this.vDueDiligentisEditable = true;
              this.myStepper.next();
            })
          },
          (error) => {
            if (Utilities.checkNoNetwork(error)) {
              swal.fire({
                title: 'Submit Document Failed!',
                text: Utilities.noNetworkMessageDetail,
                icon: 'error',
                toast: false,
                allowOutsideClick: true
              })
            } else {
              swal.fire({
                title: 'Submit Document Failed!',
                text: Utilities.getResponseBody(error),
                icon: 'error',
                toast: false,
                allowOutsideClick: true
              })
            }
          });
    }
  }

  onFileServiceSelect(e: any): void {
    try {
      const file = e.target.files[0];
      this.serviceToUpload = file;
      const fReader = new FileReader()
      fReader.readAsDataURL(file)
      fReader.onloadend = (_event: any) => {
        this.vDocument.get('sfilename').setValue(file.name);
      }
    } catch (error) {
      this.vDocument.get('sfilename').setValue(null);
      this.serviceToUpload = null;
    }
  }

  onFileServiceDownload(e: any): void {
    if (this.vDocument.get('sfilename')) {
      if (this.serviceToUpload) {
        let fileURL = URL.createObjectURL(this.serviceToUpload);
        window.open(fileURL);
      }
      else {
        this.vendorService.downloadBusineesDoc("service", this.vendor_businees.objectId).subscribe(
          respon => {
            var fileURL = URL.createObjectURL(respon);
            window.open(fileURL);
          },
          error => {
            console.error(error)
          });
      }
    }
  }


  onFileItemSelect(e: any): void {
    try {
      const file = e.target.files[0];
      this.itemToUpload = file;
      const fReader = new FileReader()
      fReader.readAsDataURL(file)
      fReader.onloadend = (_event: any) => {
        this.vDocument.get('ifilename').setValue(file.name);
      }
    } catch (error) {
      this.vDocument.get('ifilename').setValue(null);
      this.itemToUpload = null;
    }
  }

  onFileItemDownload(e: any): void {
    if (this.vDocument.get('ifilename')) {
      if (this.itemToUpload) {
        let fileURL = URL.createObjectURL(this.itemToUpload);
        window.open(fileURL);
      }
      else {
        this.vendorService.downloadBusineesDoc("item", this.vendor_businees.objectId).subscribe(
          respon => {
            var fileURL = URL.createObjectURL(respon);
            window.open(fileURL);
          },
          error => {
            console.error(error)
          });
      }
    }
  }

  onViewEdit() {
    this.clickEdit = true;
    this.loadVendorInformation();
  }

  loadVendorInformation() {
    this.vendorService.getVendorUser().subscribe(
      respon => {
        if (respon) {
          this.submitCreate = "Update";
          this.vendor_info = respon;
          this.status = respon.status;
          this.vendorid = respon.objectId;
          if (this.vendor_info.status === 'PENDING_REVIEW' || this.vendor_info.status === 'PENDING_APPROVAL') {
            this.isEdit = false;
          }
          else if (this.vendor_info.status === 'ACTIVE') {
            if (this.clickEdit) {
              this.isEdit = true;
            }
            else {
              this.isEdit = false;
            }
          }
          else {
            this.isEdit == true;
          }
        }
        this.setStep();
        this.createFormVendorInformation();
        this.loadVendorDocument();
        this.loadHistory();
      },
      error => {
        console.error(error)
      });
  }

  loadVendorDocument() {
    if (this.vendorid) {
      this.loadDue();
      this.loadBusiness();
      this.loadDoc();
      this.loadDocument();
    }
  }

  loadHistory() {
    if (this.vendorid) {
      this.vendorService.getAllVendorHistoriesByReqVendorid(this.vendorid).subscribe(
        respon => {
          this.historySource = respon;
        },
        error => {
          console.error(error)
        });
    }
  }

  loadBusiness() {
    this.vendorService.getBusineessVendor(this.vendorid)
      .subscribe(
        respon => {
          this.vendor_businees = respon;
          this.createFormVendorDocument();
        },
        error => {
          console.error(error)
        }
      );
  }

  loadDue() {
    this.vendorService.getDueVendor(this.vendorid)
      .subscribe(
        respon => {
          this.vendor_due = respon;
          this.createFormVendorDueDillegent();
        },
        error => {
          console.error(error)
        }
      );
  }

  loadDoc() {
    if (this.vendorid) {
      this.vendorService.getAllVendorDocByVendorid(this.vendorid)
        .subscribe(
          respon => {
            this.docSource = respon;
          },
          error => {
            console.error(error)
          }
        );
    }
  }

  loadDocument() {
    this.vendorService.getAllVendorDocByReqVendorid(this.vendorid).subscribe(
      respon => {
        this.vendor_doc = respon;
      },
      error => {
        console.error(error)
      });
  }

  loadCountryCode() {
    this.masterService.GetCountryCode()
      .subscribe(
        respon => {
          this.countryCodes = respon;
        },
        error => {
          console.error(error)
        }
      );
  }

  loadTemplate() {
    this.masterService.GetDueDillegentTemplate()
      .subscribe(
        respon => {
          this.due_dillegent_templates = respon;
        },
        error => {
          console.error(error)
        }
      );
  }

  onSaveInformation() {
    this.submitted = true;

    if (this.vInformation.invalid) {
      return;
    }

    if (this.vendorid) {
      const updateVendor: UpdateVendorModel = {
        vendor_id: this.vendorid,
        vendor_type: this.vInformation.controls.vendor_type.value,
        vendor_name: this.vInformation.controls.vendor_name.value,
        vendor_address_p: this.vInformation.controls.vendor_address_p.value,
        vendor_tlpn_p: this.vInformation.controls.vendor_tlpn_p.value,
        vendor_fax_p: this.vInformation.controls.vendor_fax_p.value,
        vendor_emai1_p: this.vInformation.controls.vendor_emai1_p.value,
        vendor_emai2_p: this.vInformation.controls.vendor_emai2_p.value,
        vendor_cp1_p: this.vInformation.controls.vendor_cp1_p.value,
        vendor_cp2_p: this.vInformation.controls.vendor_cp2_p.value,
        vendor_phone1_p: this.vInformation.controls.vendor_phone1_p.value,
        vendor_phone2_p: this.vInformation.controls.vendor_phone2_p.value,
        vendor_address_c: this.vInformation.controls.vendor_address_c.value,
        vendor_tlpn_c: this.vInformation.controls.vendor_tlpn_c.value,
        vendor_fax_c: this.vInformation.controls.vendor_fax_c.value,
        vendor_email1_c: this.vInformation.controls.vendor_email1_c.value,
        vendor_email2_c: this.vInformation.controls.vendor_email2_c.value,
        vendor_cp1_c: this.vInformation.controls.vendor_cp1_c.value,
        vendor_cp2_c: this.vInformation.controls.vendor_cp2_c.value,
        vendor_phone1_c: this.vInformation.controls.vendor_phone1_c.value,
        vendor_phone2_c: this.vInformation.controls.vendor_phone2_c.value,
      }

      this.vendorService.updateVendor(updateVendor).pipe()
        .subscribe(
          (res) => {
            swal.fire({
              title: 'Save Vendor Information Success!',
              text: res,
              icon: 'success',
              toast: false,
              allowOutsideClick: false,
              backdrop: true,
              confirmButtonText: 'Go to next step'
            }).then(_ => {
              this.vDocumentisEditable = true;
              this.vDocumentisComplate = true;
              this.myStepper.next();
            })
          },
          (error) => {
            if (Utilities.checkNoNetwork(error)) {
              swal.fire({
                title: 'Save Vendor Information Failed!',
                text: Utilities.noNetworkMessageDetail,
                icon: 'error',
                toast: false,
                allowOutsideClick: true
              })
            } else {
              swal.fire({
                title: 'Save Vendor Information Failed!',
                text: Utilities.getResponseBody(error),
                icon: 'error',
                toast: false,
                allowOutsideClick: true
              })
            }
          });

    }
    else {
      const createVendor: CreateVendorModel = {
        vendor_type: this.vInformation.controls.vendor_type.value,
        vendor_name: this.vInformation.controls.vendor_name.value,
        vendor_address_p: this.vInformation.controls.vendor_address_p.value,
        vendor_tlpn_p: this.vInformation.controls.vendor_tlpn_p.value,
        vendor_fax_p: this.vInformation.controls.vendor_fax_p.value,
        vendor_emai1_p: this.vInformation.controls.vendor_emai1_p.value,
        vendor_emai2_p: this.vInformation.controls.vendor_emai2_p.value,
        vendor_cp1_p: this.vInformation.controls.vendor_cp1_p.value,
        vendor_cp2_p: this.vInformation.controls.vendor_cp2_p.value,
        vendor_phone1_p: this.vInformation.controls.vendor_phone1_p.value,
        vendor_phone2_p: this.vInformation.controls.vendor_phone2_p.value,
        vendor_address_c: this.vInformation.controls.vendor_address_c.value,
        vendor_tlpn_c: this.vInformation.controls.vendor_tlpn_c.value,
        vendor_fax_c: this.vInformation.controls.vendor_fax_c.value,
        vendor_email1_c: this.vInformation.controls.vendor_email1_c.value,
        vendor_email2_c: this.vInformation.controls.vendor_email2_c.value,
        vendor_cp1_c: this.vInformation.controls.vendor_cp1_c.value,
        vendor_cp2_c: this.vInformation.controls.vendor_cp2_c.value,
        vendor_phone1_c: this.vInformation.controls.vendor_phone1_c.value,
        vendor_phone2_c: this.vInformation.controls.vendor_phone2_c.value,
      }

      this.vendorService.insertVendor(createVendor).pipe()
        .subscribe(
          (res) => {
            swal.fire({
              title: 'Save Vendor Information Success!',
              text: res,
              icon: 'success',
              toast: false,
              allowOutsideClick: false,
              backdrop: true,
              confirmButtonText: 'Go to next step'
            }).then(_ => {
              this.loadVendorInformation();
              this.vInformationisEditable = true;
              this.myStepper.next();
              this.myStepper._steps.length;

            })
          },
          (error) => {
            if (Utilities.checkNoNetwork(error)) {
              swal.fire({
                title: 'Save Vendor Information Failed!',
                text: Utilities.noNetworkMessageDetail,
                icon: 'error',
                toast: false,
                allowOutsideClick: true
              })
            } else {
              swal.fire({
                title: 'Save Vendor Information Failed!',
                text: Utilities.getResponseBody(error),
                icon: 'error',
                toast: false,
                allowOutsideClick: true
              })
            }
          });
    }
  }

  onChangeTlpnHO(e) {
    if (e.target.name === 'ho_tlpn_cc') {
      this.f_info.ho_tlpn_dc.setValue(e.target.value);
    }

    if (this.f_info.ho_tlpn_dc.value && this.f_info.ho_tlpn_ac.value && this.f_info.ho_tlpn_n.value) {
      this.f_info.vendor_tlpn_p.setValue(`${this.f_info.ho_tlpn_dc.value}-${this.f_info.ho_tlpn_ac.value}-${this.f_info.ho_tlpn_n.value}`);
    }
  }

  onChangeFaxHO(e) {
    if (e.target.name === 'ho_fax_cc') {
      this.f_info.ho_fax_dc.setValue(e.target.value);
    }

    if (this.f_info.ho_fax_dc.value && this.f_info.ho_fax_ac.value && this.f_info.ho_fax_n.value) {
      this.f_info.vendor_fax_p.setValue(`${this.f_info.ho_fax_dc.value}-${this.f_info.ho_fax_ac.value}-${this.f_info.ho_fax_n.value}`);
    }
  }

  onChangePhone1HO(e) {
    if (e.target.name === 'ho_phone1_cc') {
      this.f_info.ho_phone1_dc.setValue(e.target.value);
    }

    if (this.f_info.ho_phone1_dc.value && this.f_info.ho_phone1_ac.value && this.f_info.ho_phone1_n.value) {
      this.f_info.vendor_phone1_p.setValue(`${this.f_info.ho_phone1_dc.value}-${this.f_info.ho_phone1_ac.value}-${this.f_info.ho_phone1_n.value}`);
    }
  }

  onChangePhone2HO(e) {
    if (e.target.name === 'ho_phone2_cc') {
      this.f_info.ho_phone2_dc.setValue(e.target.value);
    }

    if (this.f_info.ho_phone2_dc.value && this.f_info.ho_phone2_ac.value && this.f_info.ho_phone2_n.value) {
      this.f_info.vendor_phone2_p.setValue(`${this.f_info.ho_phone2_dc.value}-${this.f_info.ho_phone2_ac.value}-${this.f_info.ho_phone2_n.value}`);
    }
  }

  onChangeTlpnBO(e) {
    if (e.target.name === 'bo_tlpn_cc') {
      this.f_info.bo_tlpn_dc.setValue(e.target.value);
    }

    if (this.f_info.bo_tlpn_dc.value && this.f_info.bo_tlpn_ac.value && this.f_info.bo_tlpn_n.value) {
      this.f_info.vendor_tlpn_c.setValue(`${this.f_info.bo_tlpn_dc.value}-${this.f_info.bo_tlpn_ac.value}-${this.f_info.bo_tlpn_n.value}`);
    }
  }

  onChangeFaxBO(e) {
    if (e.target.name === 'bo_fax_cc') {
      this.f_info.bo_fax_dc.setValue(e.target.value);
    }

    if (this.f_info.bo_fax_dc.value && this.f_info.bo_fax_ac.value && this.f_info.bo_fax_n.value) {
      this.f_info.vendor_fax_c.setValue(`${this.f_info.bo_fax_dc.value}-${this.f_info.bo_fax_ac.value}-${this.f_info.bo_fax_n.value}`);
    }
  }

  onChangePhone1BO(e) {
    if (e.target.name === 'bo_phone1_cc') {
      this.f_info.bo_phone1_dc.setValue(e.target.value);
    }

    if (this.f_info.bo_phone1_dc.value && this.f_info.bo_phone1_ac.value && this.f_info.bo_phone1_n.value) {
      this.f_info.vendor_phone1_c.setValue(`${this.f_info.bo_phone1_dc.value}-${this.f_info.bo_phone1_ac.value}-${this.f_info.bo_phone1_n.value}`);
    }
  }

  onChangePhone2BO(e) {
    if (e.target.name === 'bo_phone2_cc') {
      this.f_info.bo_phone2_dc.setValue(e.target.value);
    }

    if (this.f_info.bo_phone2_dc.value && this.f_info.bo_phone2_ac.value && this.f_info.bo_phone2_n.value) {
      this.f_info.vendor_phone2_c.setValue(`${this.f_info.bo_phone2_dc.value}-${this.f_info.bo_phone2_ac.value}-${this.f_info.bo_phone2_n.value}`);
    }
  }

  dueByFn(index: any, item: any) {
    return index;
  }

  onVendorSubmit() {
    this.submiteddone = true;
    if (this.vSubmit.invalid) {
      return;
    }
    const req = {
      vendorid: this.vendorid,
      remark: this.vSubmit.controls.remark.value
    }
    this.vendorService.submitVendor(req)
      .subscribe(
        (res) => {
          swal.fire({
            title: 'Submit Vendor Success!',
            text: res,
            icon: 'success',
            toast: false,
            allowOutsideClick: false,
            backdrop: true,
            confirmButtonText: 'Go To Home'
          }).then(_ => {
            this.router.navigate(['/home']);
          })
        },
        (error) => {
          if (Utilities.checkNoNetwork(error)) {
            swal.fire({
              title: 'Submit Vendor Failed!',
              text: Utilities.noNetworkMessageDetail,
              icon: 'error',
              toast: false,
              allowOutsideClick: true
            })
          } else {
            swal.fire({
              title: 'Submit Vendor Failed!',
              text: Utilities.getResponseBody(error),
              icon: 'error',
              toast: false,
              allowOutsideClick: true
            })
          }
        });
  }
}
