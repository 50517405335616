import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { now } from 'moment';
import { TokenStorage } from 'src/app/_helpers/token-storage';
import { Utilities } from 'src/app/_helpers/utilities';
import { MasterService } from 'src/app/_services/master.service';
import { VendorService } from 'src/app/_services/vendor.service';
import swal from 'sweetalert2';

@Component({
    selector: 'app-vendor-sanksi',
    templateUrl: './sanksi-vendor.component.html',
    styleUrls: ['./sanksi-vendor.component.scss']
})
export class SanksiVendorActiveComponent implements OnInit {
    isloadInfo: boolean = false;
    isloadBusiness: boolean = false;
    isloadDoc: boolean = false;
    isloadDue: boolean = false;
    vendorid: string;
    vInformation;
    vBusiness;
    vDoc;
    vDue;
    vRes1a;
    vRes2a;
    vRes2b;
    vRes3a;
    vSanksi;
    docSource: any;
    displayedColumns = ['DocName', 'No', 'StartDate', 'EndDate', 'Note', 'Action'];
    vSanksiSubmit: FormGroup;
    submitted = false;
    serviceToUpload: File = null;
    itemToUpload: File = null;
    countryCodes: any;
    historySource: any;
    historydisplayedColumns = ['Name', 'Action', 'Activity', 'Date', 'Remark'];

    constructor(
        private tokenStorage: TokenStorage,
        private route: ActivatedRoute,
        private vendorService: VendorService,
        private masterService: MasterService,
        private router: Router,
        private _formBuilder: FormBuilder
    ) {

    }

    get f_sanksi() { return this.vSanksiSubmit.controls; }

    ngOnInit(): void {
        this.vendorid = this.route.snapshot.paramMap.get('id');
        this.loadVendor();
        this.loadCountryCode();
    }

    createFormVendor() {
        const today = moment().format('yyyy-MM-DD');
        let v_no = "", v_category = "Yellow", v_desc = "", v_startdate = today, v_enddate = today;
        if(this.vSanksi){
            v_no = this.vSanksi.no;
            v_category = this.vSanksi.category;
            v_desc = this.vSanksi.description;
            v_startdate = moment(this.vSanksi.startDate).format('yyyy-MM-DD');
            v_enddate = moment(this.vSanksi.endDate).format('yyyy-MM-DD');
        }
        setTimeout(() => {
            this.vSanksiSubmit = this._formBuilder.group({
                no: [v_no],
                category: [v_category],
                desc: [v_desc],
                start_date: [v_startdate],
                end_date: [v_enddate],
            });
        });
    }

    loadVendor() {
        if (this.vendorid) {
            this.submitted = false;
            this.loadInformation();
            this.loadBusinnes();
            this.loadDocument();
            this.loadDue();
            this.loadHistory();
            this.loadSanksi();
        }
    }

    loadHistory() {
        this.vendorService.getAllVendorHistoriesByReqVendorid(this.vendorid).subscribe(
            respon => {
                this.historySource = respon;
            },
            error => {
                console.error(error)
            });
    }

    loadSanksi() {
        this.vendorService.getSanksiByVendorId(this.vendorid).subscribe(
            respon => {
                this.vSanksi = respon;
                this.createFormVendor();
            },
            error => {
                console.error(error)
            });
    }

    loadInformation() {
        this.vendorService.getVendorInfoById(this.vendorid).subscribe(
            respon => {
                this.vInformation = respon;
                this.isloadInfo = true;
            },
            error => {
                console.error(error)
            });
    }

    loadBusinnes() {
        this.vendorService.getBusineessVendor(this.vendorid).subscribe(
            respon => {
                this.vBusiness = respon;
                this.isloadBusiness = true;
            },
            error => {
                console.error(error)
            });
    }

    loadDocument() {
        this.vendorService.getAllVendorDocByReqVendorid(this.vendorid).subscribe(
            respon => {
                this.docSource = respon;
                this.isloadDoc = true;
            },
            error => {
                console.error(error)
            });
    }

    loadDue() {
        this.vendorService.getDueVendor(this.vendorid)
            .subscribe(
                respon => {
                    this.vDue = respon;
                    this.isloadDue = true;
                    this.vRes1a = respon.find(o => o.no === '1a')?.responseText;
                    this.vRes2a = respon.find(o => o.no === '2a')?.details;
                    this.vRes2b = respon.find(o => o.no === '2b')?.details;
                    this.vRes3a = respon.find(o => o.no === '3a')?.responseText;
                },
                error => {
                    console.error(error)
                }
            );
    }

    loadCountryCode() {
        this.masterService.GetCountryCode()
            .subscribe(
                respon => {
                    this.countryCodes = respon;
                },
                error => {
                    console.error(error)
                }
            );
    }



    get isShowVendorManagement() {
        if (this.tokenStorage.getToken() != null) {
            const roles = [];
            const tokeninfo = this.tokenStorage.getTokenInfo();
            let rols = tokeninfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
            if (rols instanceof Array) {
                rols.forEach(r => {
                    roles.push(r);
                });
            } else {
                roles.push(rols);
            }
            return roles.some(r => r === "ROLE_VENDOR_MANAGEMENT");
        }
        return false;
    }

    get isShowTeamManager() {
        if (this.tokenStorage.getToken() != null) {
            const roles = [];
            const tokeninfo = this.tokenStorage.getTokenInfo();
            let rols = tokeninfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
            if (rols instanceof Array) {
                rols.forEach(r => {
                    roles.push(r);
                });
            } else {
                roles.push(rols);
            }
            return roles.some(r => r === "ROLE_TEAM_MANAGER");
        }
        return false;
    }

    get isShowAdmin() {
        if (this.tokenStorage.getToken() != null) {
            const roles = [];
            const tokeninfo = this.tokenStorage.getTokenInfo();
            const rols = tokeninfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
            if (rols instanceof Array) {
                rols.forEach(r => {
                    roles.push(r);
                });
            } else {
                roles.push(rols);
            }
            return roles.some(r => r === "ROLE_ADMIN");
        }
        return false;
    }

    dueByFn(index: any, item: any) {
        return index;
    }

    onAction() {
            this.submitted = true;
            if (this.vSanksiSubmit.invalid) {
                return;
            }
            const req = {
                vendorid: this.vendorid,
                no: this.vSanksiSubmit.controls.no.value,
                category: this.vSanksiSubmit.controls.category.value,
                desc: this.vSanksiSubmit.controls.desc.value,
                startdate: this.vSanksiSubmit.controls.start_date.value,
                enddate: this.vSanksiSubmit.controls.end_date.value,
            }

            this.vendorService.postVendorSanksi(req)
                .subscribe(
                    (res) => {
                        swal.fire({
                            title: `Update Sanksi Vendor Success!`,
                            text: res,
                            icon: 'success',
                            toast: false,
                            allowOutsideClick: false,
                            backdrop: true,
                            confirmButtonText: 'Go To List Vendor'
                        }).then(_ => {
                            this.router.navigate(['/vendorreview']);
                        })
                    },
                    (error) => {
                        if (Utilities.checkNoNetwork(error)) {
                            swal.fire({
                                title: `Update Sanksi Vendor Failed!`,
                                text: Utilities.noNetworkMessageDetail,
                                icon: 'error',
                                toast: false,
                                allowOutsideClick: true
                            })
                        } else {
                            swal.fire({
                                title: `Update Sanksi Vendor Failed!`,
                                text: Utilities.getResponseBody(error),
                                icon: 'error',
                                toast: false,
                                allowOutsideClick: true
                            })
                        }
                    });
                
    }

    onCancel() {
        this.router.navigate(['/vendorreview']);
    }
}
