import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService, MessageSeverity } from '../_helpers/alert.helper';
import { TokenStorage } from '../_helpers/token-storage';
import { Utilities } from '../_helpers/utilities';
import { UserLogin } from '../_models/user-login.model';
import { AuthService } from '../_services/auth.service';
import { DntCaptchaComponent } from './dnt-captcha/dnt-captcha.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
  count = 1;
  errorText = "";
  userLogin = new UserLogin("", "");
  isLoading = false;
  formResetToggle = true;
  modalClosedCallback: () => void;
  loginStatusSubscription: any;
  errorMessage = '';
  isLoggedIn = false;
  returnUrl: string;

  @Input()
  isModal = false;

  @ViewChild(NgForm) myForm: NgForm;
  @ViewChild("appDntCaptcha") appDntCaptcha: DntCaptchaComponent;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private tokenStorage: TokenStorage,
    private route: ActivatedRoute,
    private router: Router) {
    if (this.tokenStorage.getToken()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.count = 1;
    if (this.tokenStorage.getToken()) {
      this.router.navigate(['/']);
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

  }

  clearValues() {
    this.myForm.resetForm();
  }

  ngOnDestroy() {
    if (this.loginStatusSubscription) {
      this.loginStatusSubscription.unsubscribe();
    }
  }

  getShouldRedirect() {

  }


  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  closeModal() {
    if (this.modalClosedCallback) {
      this.modalClosedCallback();
    }
  }

  mapLoginErrorMessage(error: string) {

    if (error === 'invalid_username_or_password') {
      return 'Invalid username or password';
    }

    if (error === 'invalid_grant') {
      return 'This account has been disabled';
    }

    return error;
  }

  reset() {
    this.formResetToggle = false;

    setTimeout(() => {
      this.formResetToggle = true;
    });
  }

  login() {
    this.isLoading = true;
    this.errorText = "";
    this.alertService.startLoadingMessage('', 'Attempting login...');
    if (!this.userLogin.DNTCaptchaInputText || this.userLogin.DNTCaptchaInputText == '') {
      this.errorText = 'Security code is required'
      this.isLoading = false;
      this.alertService.stopLoadingMessage();
      this.userLogin.DNTCaptchaText = "";
      return;
    }

    this.authService.login(this.userLogin, this.count.toString())
      .pipe(first())
      .subscribe(
        res => {
          this.count = 1;
          this.isLoading = false;
          this.router.navigate(['/']);
          this.alertService.stopLoadingMessage();
        },
        error => {
          this.alertService.stopLoadingMessage();
          if (Utilities.checkNoNetwork(error)) {
            this.alertService.showStickyMessage(Utilities.noNetworkMessageCaption, Utilities.noNetworkMessageDetail, MessageSeverity.error, error);
            this.errorText = this.mapLoginErrorMessage(Utilities.noNetworkMessageDetail)
          } else {
            const errorMessage = Utilities.getHttpResponseMessage(error);
            if (errorMessage) {
              if (errorMessage === 'security code invalid')
                this.errorText = errorMessage;
              else
                this.errorText = this.mapLoginErrorMessage(errorMessage);

              this.alertService.showStickyMessage('Unable to login', this.mapLoginErrorMessage(errorMessage), MessageSeverity.error, error);
            } else {
              this.alertService.showStickyMessage('Unable to login', 'An error occured whilst logging in, please try again later.\nError: ' + Utilities.getResponseBody(error), MessageSeverity.error, error);
              this.errorText = this.mapLoginErrorMessage(Utilities.getResponseBody(error));
            }
          }

          setTimeout(() => {
            this.count++;
            this.isLoading = false;
            this.userLogin.DNTCaptchaInputText = '';
            this.alertService.stopLoadingMessage();
            this.myForm.resetForm();
            this.appDntCaptcha.doRefresh();
          }, 1000);
        }
      );
  }
}
