import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorage } from 'src/app/_helpers/token-storage';
import { Utilities } from 'src/app/_helpers/utilities';
import { MasterService } from 'src/app/_services/master.service';
import { VendorService } from 'src/app/_services/vendor.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-vendor-inactive',
  templateUrl: './inactive-vendor.component.html',
  styleUrls: ['./inactive-vendor.component.scss']
})
export class InactiveVendorActiveComponent implements OnInit {
  action: string = 'Submit';
  actionx: number = 99;
  isloadInfo: boolean = false;
  isloadBusiness: boolean = false;
  isloadDoc: boolean = false;
  isloadDue: boolean = false;
  vendorid: string;
  vInformation;
  vBusiness;
  vDoc;
  vDue;
  vRes1a;
  vRes2a;
  vRes2b;
  vRes3a;
  docSource: any;
  displayedColumns = ['DocName', 'No', 'StartDate', 'EndDate', 'Note', 'Action'];
  vSubmit: FormGroup;
  submitted = false;
  vInfoBusinessEdit: FormGroup;
  vDueDiligent: FormGroup;
  serviceToUpload: File = null;
  itemToUpload: File = null;
  countryCodes: any;
  historySource: any;
  historydisplayedColumns = ['Name', 'Action', 'Activity', 'Date', 'Remark'];

  constructor(
    private tokenStorage: TokenStorage,
    private route: ActivatedRoute,
    private vendorService: VendorService,
    private masterService: MasterService,
    private router: Router,
    private _formBuilder: FormBuilder
    ) {

  }

  get f_infobusiness_edit() { return this.vInfoBusinessEdit.controls; }
  get f_due() { return this.vDueDiligent.controls; }

  ngOnInit(): void {
    this.vendorid = this.route.snapshot.paramMap.get('id');
    this.loadVendor();
    this.loadCountryCode();
    this.createFormVendor();
  }

  createFormVendor() {
    setTimeout(() => {
      this.vSubmit = this._formBuilder.group({
        remark: [""]
      });
    });
  }

  loadVendor() {
    if (this.vendorid) {
      this.submitted = false;
      this.loadInformation();
      this.loadBusinnes();
      this.loadDocument();
      this.loadDue();
      this.loadHistory();
    }
  }

  loadHistory(){
    this.vendorService.getAllVendorHistoriesByReqVendorid(this.vendorid).subscribe(
      respon => {
        this.historySource = respon;
      },
      error => {
        console.error(error)
      });
  }

  loadInformation() {
    this.vendorService.getVendorInfoById(this.vendorid).subscribe(
      respon => {
        this.vInformation = respon;
        if(respon.status == "ACTIVE" || respon.status == "EXPIRED"){
          this.action = "Non Active"
          this.actionx = 0;
        }
        if(respon.status == "INACTIVE"){
          this.action = "Active"
          this.actionx = 1;
        }
        this.isloadInfo = true;
      },
      error => {
        console.error(error)
      });
  }

  loadBusinnes() {
    this.vendorService.getBusineessVendor(this.vendorid).subscribe(
      respon => {
        this.vBusiness = respon;
        this.isloadBusiness = true;
      },
      error => {
        console.error(error)
      });
  }

  loadDocument() {
    this.vendorService.getAllVendorDocByReqVendorid(this.vendorid).subscribe(
      respon => {
        this.docSource = respon;
        this.isloadDoc = true;
      },
      error => {
        console.error(error)
      });
  }

  loadDue() {
    this.vendorService.getDueVendor(this.vendorid)
      .subscribe(
        respon => {
          this.vDue = respon;
          this.isloadDue = true;
          this.vRes1a = respon.find(o => o.no === '1a')?.responseText;
          this.vRes2a = respon.find(o => o.no === '2a')?.details;
          this.vRes2b = respon.find(o => o.no === '2b')?.details;
          this.vRes3a = respon.find(o => o.no === '3a')?.responseText;
        },
        error => {
          console.error(error)
        }
      );
  }

  loadCountryCode() {
    this.masterService.GetCountryCode()
      .subscribe(
        respon => {
          this.countryCodes = respon;
        },
        error => {
          console.error(error)
        }
      );
  }

  

  get isShowVendorManagement() {
    if(this.tokenStorage.getToken() != null){
      const roles = [];
      const tokeninfo = this.tokenStorage.getTokenInfo();
      let rols = tokeninfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      if (rols instanceof Array) {
        rols.forEach(r => {
          roles.push(r);
        });
      } else {
        roles.push(rols);
      }
      return roles.some(r => r === "ROLE_VENDOR_MANAGEMENT");
    } 
    return false;
  }

  get isShowTeamManager() {
    if(this.tokenStorage.getToken() != null){
      const roles = [];
      const tokeninfo = this.tokenStorage.getTokenInfo();
      let rols = tokeninfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      if (rols instanceof Array) {
        rols.forEach(r => {
          roles.push(r);
        });
      } else {
        roles.push(rols);
      }
      return roles.some(r => r === "ROLE_TEAM_MANAGER");
    } 
    return false;
  }

  get isShowAdmin() {
    if(this.tokenStorage.getToken() != null){
      const roles = [];
      const tokeninfo = this.tokenStorage.getTokenInfo();
      const rols = tokeninfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      if (rols instanceof Array) {
        rols.forEach(r => {
          roles.push(r);
        });
      } else {
        roles.push(rols);
      }
      return roles.some(r => r === "ROLE_ADMIN");
    } 
    return false;
  }

  dueByFn(index: any, item: any) {
    return index;
  }

  onAction(){
    if(this.actionx != 99){
      this.submitted = true;
      if (this.vSubmit.invalid) {
        return;
      }
      const req = {
        vendorid: this.vendorid,
        remark: this.vSubmit.controls.remark.value
      }
  
      this.vendorService.activeinactiveVendor(this.actionx, req)
        .subscribe(
          (res) => {
            swal.fire({
              title: `${this.action} Vendor Success!`,
              text: res,
              icon: 'success',
              toast: false,
              allowOutsideClick: false,
              backdrop: true,
              confirmButtonText: 'Go To List Vendor'
            }).then(_ => {
              this.router.navigate(['/vendorreview']);
            })
          },
          (error) => {
            if (Utilities.checkNoNetwork(error)) {
              swal.fire({
                title: `${this.action} Vendor Failed!`,
                text: Utilities.noNetworkMessageDetail,
                icon: 'error',
                toast: false,
                allowOutsideClick: true
              })
            } else {
              swal.fire({
                title: `${this.action} Vendor Failed!`,
                text: Utilities.getResponseBody(error),
                icon: 'error',
                toast: false,
                allowOutsideClick: true
              })
            }
          });
    }
    else{
      swal.fire({
        title: `${this.action} Vendor Failed!`,
        text: "Something Wrong!",
        icon: 'error',
        toast: false,
        allowOutsideClick: true
      })
    }
    
  }

  onCancel(){
    this.router.navigate(['/vendorreview']);
  }
}
