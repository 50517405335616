import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

const ACCESS_TOKEN = 'access-token';
const REFRESH_TOKEN = 'refresh-token';

@Injectable({
  providedIn: 'root'
})
export class TokenStorage {
  constructor() { }

  signOut(): void {
    localStorage.clear();
  }

  public saveToken(token: string, refresh: string): void {
    localStorage.clear();
    localStorage.setItem(ACCESS_TOKEN, token);
    localStorage.setItem(REFRESH_TOKEN, refresh);
  }

  public getToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  public getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  public clearToken(): void {
    localStorage.clear();
  }

  public getTokenInfo(): any {
    let token = localStorage.getItem(ACCESS_TOKEN);
    if (token)
      return jwt_decode(token);

    return null;
  }
}
