import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, DefaultUrlSerializer, UrlTree } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { UserComponent } from './user/list/user.component';
import { AboutComponent } from './about/about.component';
import { Utilities } from './_helpers/utilities';
import { AuthGuard } from './_helpers/auth.guard';
import { UserAddComponent } from './user/add/user-add.component';
import { UserEditComponent } from './user/edit/user-edit.component';
import { VendorComponent } from './vendor/uservendor/vendor.component';
import { VendorReviewComponent } from './vendor/reviewvendor/list-review.component';
import { ViewVendorComponent } from './vendor/view-vendor/view-vendor.component';
import { ListApprovalComponent } from './vendor/listapproval/list-approval.component';
import { ViewApprovalComponent } from './vendor/view-approval/view-approval.component';
import { ViewVendorActiveComponent } from './vendor/view-vendor/view-vendoractive.component';
import { InactiveVendorActiveComponent } from './vendor/view-vendor/inactive-vendor.component';
import { SanksiVendorActiveComponent } from './vendor/view-vendor/sanksi-vendor.component';
import { ChangePasswordComponent } from './user/own/change-password.component';
import { ProfileComponent } from './user/own/profile.component';


@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree {
        const possibleSeparators = /[?;#]/;
        const indexOfSeparator = url.search(possibleSeparators);
        let processedUrl: string;

        if (indexOfSeparator > -1) {
            const separator = url.charAt(indexOfSeparator);
            const urlParts = Utilities.splitInTwo(url, separator);
            urlParts.firstPart = urlParts.firstPart.toLowerCase();

            processedUrl = urlParts.firstPart + separator + urlParts.secondPart;
        } else {
            processedUrl = url.toLowerCase();
        }

        return super.parse(processedUrl);
    }
}

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] , data: { title: 'Home' }},
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { 
    path: 'user', 
    component: UserComponent, 
    canActivate: [AuthGuard] , 
    data: { title: 'User Management' }
  },
  {
    path : 'user/add',
    component : UserAddComponent,
    canActivate : [AuthGuard],
    data: { title: 'User Management' },
  },
  {
    path : 'user/edit/:id',
    component : UserEditComponent,
    canActivate : [AuthGuard],
    data: { title: 'User Management' },
  },
  { path: 'vendor', component: VendorComponent, canActivate: [AuthGuard], data: { title: 'Vendor' } },
  { path: 'vendorreview', component: VendorReviewComponent, canActivate: [AuthGuard], data: { title: 'Daftar Vendor' } },
  { path: 'vendorapproval', component: ListApprovalComponent, canActivate: [AuthGuard], data: { title: 'Daftar Vendor' } },
  { path: 'vendorreview/view/:id', component: ViewVendorComponent, canActivate: [AuthGuard], data: { title: 'Vendor Review' } },
  { path: 'vendorapproval/view/:id', component: ViewApprovalComponent, canActivate: [AuthGuard], data: { title: 'Vendor Approval' } },
  { path: 'vendoractive/view/:id', component: ViewVendorActiveComponent, canActivate: [AuthGuard], data: { title: 'Vendor View' } },
  { path: 'vendorinactive/view/:id', component: InactiveVendorActiveComponent, canActivate: [AuthGuard], data: { title: 'Vendor View' } },
  { path: 'vendorsanksi/view/:id', component: SanksiVendorActiveComponent, canActivate: [AuthGuard], data: { title: 'Vendor View' } },
  { path: 'about', component: AboutComponent, data: { title: 'About' } },
  { path: 'home', redirectTo: '/', pathMatch: 'full' },
  { 
    path: 'chgpwd', 
    component: ChangePasswordComponent, 
    canActivate: [AuthGuard] , 
    data: { title: 'Settings' }
  },
  { 
    path: 'profile', 
    component: ProfileComponent, 
    canActivate: [AuthGuard] , 
    data: { title: 'Settings' }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
