import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Utilities } from 'src/app/_helpers/utilities';
import { RegisterModel } from 'src/app/_models/register-model';
import { MasterService } from 'src/app/_services/master.service';
import { UserService } from 'src/app/_services/user.service';
import swal from 'sweetalert2';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
    isLoading = false;
    isPhoto = false;
    profileForm: FormGroup;
    vphoto: any;
    vUser: any;
    submitted = false;
    roles = [];
    countryCodes: any;

    constructor(
        private masterService: MasterService,
        private router: Router,
        private userService: UserService,
        private formBuilder: FormBuilder) {

    }

    get f() { return this.profileForm.controls; }

    ngOnInit() {
        this.loadCountryCode();
        this.loadUser();
    }

    loadCountryCode() {
        this.masterService.GetCountryCode()
            .subscribe(
                respon => {
                    this.countryCodes = respon;
                },
                error => {
                    console.error(error)
                }
            );
    }
    loadUser() {
        this.userService.getUserLogin().subscribe(
            respon => {
                if (respon) {
                    this.vUser = respon;
                    this.createForm();
                }
            },
            error => {
                console.error(error)
            });

    }

    createForm() {
        let v_firstName = "", v_lastName = "", v_email = "", v_gender = "", v_phone = "", v_phone_cc = "", v_phone_dc = "", v_phone_ac = "", v_phone_n = "", v_address = "", v_photo = "";
        if (this.vUser) {
            v_firstName = this.vUser.firstname;
            v_lastName = this.vUser.lastname;
            v_email = this.vUser.email;
            v_gender = this.vUser.gender;
            v_phone = this.vUser.phone;
            v_address = this.vUser.address;
            v_photo = this.vUser.photo;

            if (this.vUser.photo)
                this.isPhoto = true;

            if (this.vUser.phone) {
                let vphone = this.vUser.phone.split('-');
                v_phone_cc = vphone[0];
                v_phone_dc = vphone[0];
                v_phone_ac = vphone[1];
                v_phone_n = vphone[2];
            }
        }
        setTimeout(() => {
            this.profileForm = this.formBuilder.group({
                firstName: [v_firstName, Validators.required],
                lastName: [v_lastName],
                email: [v_email, [Validators.required, Validators.email]],
                gender: [v_gender],
                phone: [v_phone],
                phone_cc: [v_phone_cc],
                phone_dc: [{ value: v_phone_dc, disabled: true }],
                phone_ac: [v_phone_ac, Validators.required],
                phone_n: [v_phone_n, Validators.required],
                address: [v_address],
                photo: [v_photo]
            });
        });
    }

    onFileChanged(event) {
        const files = event.target.files;
        if (files.length === 0) {
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
            if (reader.result)
                this.isPhoto = true;

            this.profileForm.controls.photo.setValue(reader.result);
        };
    }

    onCancel() {
        this.router.navigate(['/home']);
    }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.profileForm.invalid) {
            return;
        }

        let oprofile = {
            email: this.profileForm.controls.email.value,
            firstname: this.profileForm.controls.firstName.value,
            lastname: this.profileForm.controls.lastName.value,
            phone: this.profileForm.controls.phone.value,
            gender: this.profileForm.controls.gender.value,
            address: this.profileForm.controls.address.value,
            photo: this.profileForm.controls.photo.value == '' ? null : this.profileForm.controls.photo.value
        }

        this.userService.updateProfile(oprofile).pipe()
            .subscribe(
                (res) => {
                    swal.fire({
                        title: 'Change Profile Success!',
                        text: res,
                        icon: 'success',
                        toast: false,
                        allowOutsideClick: false,
                        backdrop: true,
                        confirmButtonText: 'Goto Home'
                    }).then(_ => {
                        this.router.navigate(['/']);
                    })
                },
                (error) => {
                    if (Utilities.checkNoNetwork(error)) {
                        swal.fire({
                            title: 'Change Profile Failed!',
                            text: Utilities.noNetworkMessageDetail,
                            icon: 'error',
                            toast: false,
                            allowOutsideClick: true
                        })
                    } else {
                        swal.fire({
                            title: 'Change Profile Failed!',
                            text: Utilities.getResponseBody(error),
                            icon: 'error',
                            toast: false,
                            allowOutsideClick: true
                        })
                    }
                });

        this.isLoading = true;

    }

    onChangePhone(e) {
        if (e.target.name === 'phone_cc') {
            this.f.phone_dc.setValue(e.target.value);
        }

        if (this.f.phone_dc.value && this.f.phone_ac.value && this.f.phone_n.value) {
            this.f.phone.setValue(`${this.f.phone_dc.value}-${this.f.phone_ac.value}-${this.f.phone_n.value}`);
        }
    }

}