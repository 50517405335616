import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Utilities } from 'src/app/_helpers/utilities';
import { UserService } from 'src/app/_services/user.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  isLoading = false;
  displayedColumns = ['Email', 'Firstname', 'Lastname', 'Address', 'Phone', 'LastLogin', 'Roles', 'Status', 'Action'];
  pageSize = 10;
  currentPage = 0;
  totalSize = 0;
  search = '';
  order = 'u.Firstname asc';
  dataSource: any;

  @ViewChild(MatPaginator) paginator: MatPaginator; // for mat-paginator
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private userService: UserService,
    private router: Router) {

  }

  ngOnInit(): void {
    this.loadUser();
  }

  add() {
    this.router.navigate['/add'];
  }

  loadUser() {
    this.isLoading = true;
    this.userService.getAllWithPaging(this.search, this.order, this.currentPage + 1, this.pageSize).subscribe(
      respon => {
        this.dataSource = respon.data;
        this.paginator.length = respon.records;
      },
      error => {
        console.error(error)
      }
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }

  changeStatus(id: string, status: boolean) {
    this.isLoading = true;
    this.userService.updateUserStatus(id, status).subscribe(
      res => {
        swal.fire({
          title: 'Success!',
          text: res,
          icon: 'success',
          toast: false,
          allowOutsideClick: false,
          backdrop: true,
          confirmButtonText: 'Ok'
        }).then(_ => {
          this.loadUser();
        })
      },
      err => {
        if (Utilities.checkNoNetwork(err)) {
          swal.fire({
            title: 'Failed!',
            text: Utilities.noNetworkMessageDetail,
            icon: 'error',
            toast: false,
            allowOutsideClick: true
          })
        } else {
          swal.fire({
            title: 'Failed!',
            text: Utilities.getResponseBody(err),
            icon: 'error',
            toast: false,
            allowOutsideClick: true
          })
        }
      }
    )
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }


  newPassword(id: string) {
    this.isLoading = true;
    this.userService.updateUserNewPassword(id).subscribe(
      res => {
        swal.fire({
          title: 'Success!',
          text: res,
          icon: 'success',
          toast: false,
          allowOutsideClick: false,
          backdrop: true,
          confirmButtonText: 'Ok'
        }).then(_ => {
          this.loadUser();
        })
      },
      err => {
        if (Utilities.checkNoNetwork(err)) {
          swal.fire({
            title: 'Failed!',
            text: Utilities.noNetworkMessageDetail,
            icon: 'error',
            toast: false,
            allowOutsideClick: true
          })
        } else {
          swal.fire({
            title: 'Failed!',
            text: Utilities.getResponseBody(err),
            icon: 'error',
            toast: false,
            allowOutsideClick: true
          })
        }
      }
    )
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }


  changePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.loadUser();
  }

  onSearch(e: any) {
    if (e.keyCode === 13 || e.code === 'Enter') {
      this.loadUser();
    }
  }

  clearSearch() {
    this.search = '';
    this.loadUser();
  }

  applySort(e: any) {
    this.order = `u.${e.active} ${e.direction}`;
    this.loadUser();
  }

  onActiveNonActive(row) {
    if (row) {
      swal.fire({
        title: "Are you sure?",
        text: `To ${row.isActive ? 'deactivate ' : 'activate'} user ${row.firstname} ${row.lastname}`,
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true
      })
        .then((willDelete) => {
          if (willDelete.value) {
            this.changeStatus(row.objectId, !row.isActive);
          }
        });
    }
  }

  onEdit(row){
    if(row){
      this.router.navigate(['/user/edit', row.objectId]);
    }
  }

  onGenereteNewPassword(row){
    if (row) {
      swal.fire({
        title: "Are you sure?",
        text: `To generate new password for user ${row.firstname} ${row.lastname}`,
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true
      })
        .then((willDelete) => {
          if (willDelete.value) {
            this.newPassword(row.objectId);
          }
        });
    }
  }
}
