import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/_helpers/loading.service';
import { PdfgeneratorService } from 'src/app/_services/pdf.service';
import { VendorService } from 'src/app/_services/vendor.service';

@Component({
  selector: 'app-vendor-reviewlist',
  templateUrl: './list-review.component.html',
  styleUrls: ['./list-review.component.scss']
})
export class VendorReviewComponent implements OnInit {
  pdfUrl = null;
  isLoading = false;
  displayedColumns = ['VendorName', 'VendorID', 'NoSKKV', 'SKKVStartDate', 'SKKVEndDate', 'HOAddress', 'HONoTlpn', 'HONoFax', 'HONoHandphone', 'HOEmail', 'SIUP', 'BidangUsaha', 'Status', 'Action'];
  pageSize = 10;
  currentPage = 0;
  totalSize = 0;
  search = '';
  order = 'v.VendorName asc';
  dataSource: any;
  vInfo: any;
  vDoc: any;
  vBusiness: any;

  @ViewChild(MatPaginator) paginator: MatPaginator; // for mat-paginator
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private vendorService: VendorService,
    private pdfService: PdfgeneratorService,
    private router: Router,
    private readonly loadingService: LoadingService) {

  }

  ngOnInit(): void {
    this.loadVendor();
  }

  changePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.loadVendor();
  }

  loadVendor() {
    this.isLoading = true;
    this.vendorService.getAllWithRolePaging(this.search, this.order, this.currentPage + 1, this.pageSize).subscribe(
      respon => {
        this.dataSource = respon.data;
        this.paginator.length = respon.records;
      },
      error => {
        console.error(error)
      }
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }

  onReview(row) {
    if (row) {
      this.router.navigate(['/vendorreview/view', row.objectId]);
    }
  }

  onView(row) {
    if (row) {
      this.router.navigate(['/vendoractive/view', row.objectId]);
    }
  }

  onViewInActive(row) {
    if (row) {
      this.router.navigate(['/vendorinactive/view', row.objectId]);
    }
  }

  onViewActive(row) {
    if (row) {
      this.router.navigate(['/vendorinactive/view', row.objectId]);
    }
  }

  onViewSanksi(row) {
    if (row) {
      this.router.navigate(['/vendorsanksi/view', row.objectId]);
    }
  }

  loadInfo(vendorid) {
    this.vendorService.getVendorInfoById(vendorid).subscribe(
      respon => {
        this.vInfo = respon;
      },
      error => {
        console.error(error)
      });
  }

  loadBusinnes(vendorid) {
    this.vendorService.getBusineessVendor(vendorid).subscribe(
      respon => {
        this.vBusiness = respon;
      },
      error => {
        console.error(error)
      });
  }

  loadDocument(vendorid) {
    this.vendorService.getAllVendorDocByReqVendorid(vendorid).subscribe(
      respon => {
        this.vDoc = respon;
      },
      error => {
        console.error(error)
      });
  }

  loadData(vendorid: string) {
    this.loadInfo(vendorid);
    this.loadBusinnes(vendorid);
    this.loadDocument(vendorid);
  }

  onPrint(row) {
    this.vInfo = null;
    this.vBusiness = null;
    this.vDoc = null;
    if (row) {
      this.vendorService.getVendorInfoById(row.objectId).subscribe(
        respon => {
          this.vInfo = respon;
          this.vendorService.getBusineessVendor(row.objectId).subscribe(
            respon => {
              this.vBusiness = respon;
              this.vendorService.getAllVendorDocByReqVendorid(row.objectId).subscribe(
                respon => {
                  this.vDoc = respon;
                  this.loadingService.show();
                  setTimeout(() => {
                    if (this.vInfo && this.vDoc && this.vBusiness) {
                      this.pdfService.generatePdfUrl(this.vInfo, this.vDoc, this.vBusiness);
                      this.loadingService.hide();
                    }
                    else {
                      this.loadingService.hide();
                    }
                  }, 1000);
                },
                error => {
                  console.error(error)
                });
            },
            error => {
              console.error(error)
            });
        },
        error => {
          console.error(error)
        });
    }
  }

  onSearch() {
    this.currentPage = 0;
    this.loadVendor();
  }

  clearSearch() {
    this.search = '';
    this.loadVendor();
  }

  exportToExcel(): void {
    this.vendorService.exportToexcel(this.search, this.order).subscribe(
      respon => {
        var fileURL = URL.createObjectURL(respon);
        window.open(fileURL);
      },
      error => {
        console.error(error)
      });
  }
}
