import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { DNTCaptchaApiResponse } from "./dnt-captcha-api-response";
import { DNTCaptchaLanguage } from "./dnt-captcha-language";
import { AuthService } from "src/app/_services/auth.service";

@Component({
  selector: "dnt-captcha",
  templateUrl: "./dnt-captcha.component.html",
  styleUrls: ["./dnt-captcha.component.css"],
})
export class DntCaptchaComponent implements OnInit {
  apiResponse = new DNTCaptchaApiResponse();
  hiddenInputName = "DNTCaptchaText";
  hiddenTokenName = "DNTCaptchaToken";
  inputName = "DNTCaptchaInputText";

  @Input() text: string;
  @Output() textChange = new EventEmitter<string>();

  @Input() token: string;
  @Output() tokenChange = new EventEmitter<string>();

  @Input() inputText: string;
  @Output() inputTextChange = new EventEmitter<string>();

  @Input() placeholder: string;
  @Input() errorText: string;
  @Input() backColor: string;
  @Input() fontName: string;
  @Input() fontSize: number;
  @Input() foreColor: string;
  @Input() language: DNTCaptchaLanguage;
  @Input() max: number;
  @Input() min: number;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.doRefresh();
  }

  doRefresh() {
    this.inputText = "";
    this.authService.getCaptchaInfo().subscribe((data) => {
      this.apiResponse = data;
      this.text = data.dntCaptchaTextValue;
      this.onTextChange();
      this.token = data.dntCaptchaTokenValue;
      this.onTokenChange();
    });
  }

  onTextChange() {
    this.textChange.emit(this.text);
  }

  onTokenChange() {
    this.tokenChange.emit(this.token);
  }

  onInputTextChange() {
    this.inputTextChange.emit(this.inputText);
  }
}
